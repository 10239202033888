import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import {Cost} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {DataQualityTag} from "../diy/components/data-quality-tag"

type Props = {
  type: "replace" | "doItForMe" | "doItYourself"
  cost: Cost | null
}

export const Price: FC<Props> = ({cost, type}) => {
  const t = useTranslation()

  if (!cost) {
    return <NoPrice type={type} />
  }

  const priceString = t(
    type === "doItYourself" ? "general.price" : "general.priceNoCents",
    {
      price: cost.cost,
    },
  )

  const isDiyWithNoParts = type === "doItYourself" && cost.cost === 0

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        gap: 4,
        minHeight: 100,
      })}
    >
      <div
        className={hstack({
          gap: 16,
          alignItems: "center",
          marginBottom: 12,
        })}
      >
        <div
          className={css({
            textStyle: "h3",
            color: "fontBlack",
          })}
        >
          {type !== "replace" && cost.source.type !== "match" && (
            <span
              className={css({
                color: "rgba(117, 117, 117, 1)",
              })}
            >
              {"~"}
            </span>
          )}
          {priceString}
        </div>
        {cost.range && (
          <div className={css({height: 32})}>
            <p className={css({textStyle: "caption", color: "fontGrey"})}>
              {t("cause.nextStep.replace.fullRange")}
            </p>
            <span className={css({textStyle: "caption", color: "fontGrey"})}>
              {t("general.priceRangeNoCents", {
                bottom: cost.range.low,
                top: cost.range.high,
              })}
            </span>
          </div>
        )}
      </div>
      <DataQualityTag
        source={cost.source}
        overrideColorScheme={
          type === "replace" || isDiyWithNoParts ? "lightBlue" : undefined
        }
        overrideTitle={
          isDiyWithNoParts
            ? t("cause.nextStep.dataQuality.price.noPartsRequired")
            : undefined
        }
        className={css({alignSelf: "flex-start"})}
      />
      <div
        className={css({
          textStyle: "caption",
          color: "fontGrey",
          marginTop: 12,
        })}
      >
        {
          t(
            isDiyWithNoParts
              ? "cause.nextStep.priceLabel.doItYourselfNoParts"
              : type === "replace"
                ? "cause.nextStep.priceLabel.replace"
                : type === "doItForMe"
                  ? "cause.nextStep.priceLabel.doItForMe"
                  : "cause.nextStep.priceLabel.doItYourself",
          ) as string
        }
      </div>
    </div>
  )
}

const NoPrice: FC<Pick<Props, "type">> = ({type}) => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        height: 80,
        paddingX: 30,
        paddingY: 10,
        justifyContent: "center",
        textStyle: "caption",
        color: "darkGrey",
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: "darkGrey",
        borderRadius: 4,
        textAlign: "center",
      })}
    >
      {t(`cause.nextStep.noPrice.${type}`) as string}
    </div>
  )
}
