import {isToday} from "date-fns"
import {TFunction} from "i18next"
import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

interface Props {
  resolved: boolean
  date: Date
}

export const CaseState: FC<Props> = ({resolved, date}) => {
  const t = useTranslation()

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "row",
        gap: 6,
        textStyle: "caption",
      })}
    >
      <span
        className={css(
          resolved
            ? {
                color: "lightBlue",
              }
            : {color: "yellow"},
        )}
      >
        {t(
          resolved
            ? "appContainer.caseState.resolved"
            : "appContainer.caseState.inProgress",
        )}
      </span>
      <span
        className={css({
          color: "fontGrey",
          "&::before": {
            content: "'•'",
            paddingRight: 6,
          },
        })}
      >
        {formatDate(date, t)}
      </span>
    </div>
  )
}

const formatDate = (date: Date, t: TFunction): string =>
  isToday(date)
    ? t("appContainer.sidebar.cases.today")
    : t("appContainer.sidebar.cases.date", {date})
