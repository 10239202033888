import {format, isToday, isYesterday} from "date-fns"
import {FC, PropsWithChildren} from "react"
import {ReactSVG} from "react-svg"

import riLogo from "@frontend/design/icons/ri-logo.svg"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"
type Props = {
  className?: string
} & (
  | {
      isThinkingOrAI: true
      isInitialPage: true
      timestamp?: never
    }
  | {
      isThinkingOrAI: true
      timestamp: Date
      isInitialPage?: never
    }
  | {
      isThinkingOrAI: false
      isInitialPage?: false
      timestamp: Date
    }
)

export const messageContainerClass = "msg-container"

export const EventLayout: FC<PropsWithChildren<Props>> = (props) => {
  if (props.isInitialPage && props.isThinkingOrAI) {
    return (
      <AIEventLayout
        className={props.className}
        isInitialPage={props.isInitialPage}
      >
        {props.children}
      </AIEventLayout>
    )
  }

  return props.isThinkingOrAI ? (
    <AIEventLayout
      timestamp={props.timestamp}
      className={props.className}
      isInitialPage={props.isInitialPage}
    >
      {props.children}
    </AIEventLayout>
  ) : (
    <UserEventLayout className={props.className} timestamp={props.timestamp}>
      {props.children}
    </UserEventLayout>
  )
}

interface UserEventLayoutProps {
  className?: string
  timestamp: Date
}

const UserEventLayout: FC<PropsWithChildren<UserEventLayoutProps>> = ({
  className,
  children,
  timestamp,
}) => (
  <div
    className={`${vstack({
      gap: 6,
      alignItems: "flex-end",
    })} ${messageContainerClass}`}
  >
    <Timestamp timestamp={timestamp} sender="user" />
    <div
      className={cx(
        css({
          backgroundColor: "royalBlue",
          borderRadius: "24px 24px 4px 24px",
          paddingX: 20,
          paddingY: 12,
          border: "1px #CADDF8 solid",
          width: "fit-content",
          marginLeft: "auto",
        }),
        className,
      )}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: 16,
          desktop: {
            gap: 32,
            flexDirection: "row",
          },
          alignItems: "flex-start",
        })}
      >
        {children}
      </div>
    </div>
  </div>
)

interface AIEventLayoutProps {
  timestamp?: Date
  className?: string
  isInitialPage?: boolean
}

const AIEventLayout: FC<PropsWithChildren<AIEventLayoutProps>> = ({
  timestamp,
  className,
  children,
  isInitialPage,
}) => (
  <div
    className={`${cx(
      css({
        display: "flex",
        flexDirection: {base: "column", desktop: "row"},
        alignItems: {base: "stretch", desktop: "flex-start"},
        gap: 16,
        padding: "16px 56px 16px 0px",
      }),
      className,
    )} ${messageContainerClass}`}
  >
    {!isInitialPage && (
      <div
        className={hstack({
          gap: 16,
        })}
      >
        <ReactSVG src={riLogo} />
        <Timestamp
          timestamp={timestamp}
          className={css({
            display: {base: "block", desktop: "none"},
          })}
          sender="ai"
        />
      </div>
    )}
    <div
      className={vstack({
        alignItems: "stretch",
        gap: 12,
        minWidth: 0,
        flexGrow: 1,
        paddingY: 6,
      })}
    >
      {!isInitialPage && (
        <Timestamp
          timestamp={timestamp}
          className={css({
            display: {base: "none", desktop: "block"},
          })}
          sender="ai"
        />
      )}
      {children}
    </div>
  </div>
)

interface TimestampProps {
  timestamp?: Date
  className?: string
  sender?: "ai" | "user"
}

const Timestamp: FC<TimestampProps> = ({timestamp, className, sender}) => {
  const t = useTranslation()
  if (!timestamp) {
    return null
  }
  const time = format(timestamp, "h:mm a")

  const timestampString = isToday(timestamp)
    ? t("conversation.relativeDate.today", {time})
    : isYesterday(timestamp)
      ? t("conversation.relativeDate.yesterday", {time})
      : t("conversation.calendarDate", {date: timestamp})

  const timeStampWithSender =
    sender === "user"
      ? t("conversation.senderWithTimeStamp.user", {timestamp: timestampString})
      : t("conversation.senderWithTimeStamp.ai", {timestamp: timestampString})

  return (
    <span
      className={cx(
        css({
          color: "slateGray",
          height: 16,
          fontWeight: 500,
          fontSize: 13,
          lineHeight: "16px",
          display: "block",
          letterSpacing: "-0.02em",
          minWidth: "max-content",
        }),
        className,
      )}
    >
      {timeStampWithSender}
    </span>
  )
}
