import {FC, PropsWithChildren} from "react"

import {css, cx} from "@styled-system/css"

import {ButtonProps} from "./buttonProps"

export interface UnstyledProps extends PropsWithChildren {
  type: "unstyled"
  className?: string
}

export const UnstyledButton: FC<UnstyledProps & ButtonProps> = ({
  children,
  className,
  disabled,
  onClick,
  id,
  ariaLabel = "",
}) => (
  <button
    className={cx(
      css({
        _enabled: {
          cursor: "pointer",
        },
      }),
      className,
    )}
    disabled={disabled}
    onClick={onClick}
    id={id}
    aria-label={ariaLabel}
  >
    {children}
  </button>
)
