import {FC} from "react"

import {Button} from "@frontend/components"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {TABS, useCurrentTab} from "../util/tabs"

interface Props {
  caseId: string
  causeOnMessageId: number
  className?: string
}

export const Tabs: FC<Props> = ({caseId, causeOnMessageId, className}) => {
  const currentTab = useCurrentTab()
  const t = useTranslation()

  return (
    <div
      className={hstack({
        justifyContent: "space-between",
        borderBottomWidth: 1,
        borderBottomColor: "grey",
        marginX: 0,
        largeDesktop: {
          borderBottomColor: "transparent",
          gap: 32,
          marginX: 16,
        },
      })}
    >
      <div
        className={cx(
          hstack({
            gap: {base: 24, largeDesktop: 32},
          }),
          className,
        )}
      >
        {TABS.map((tab) => (
          <Button
            navigateTo={makePath({
              name: "cases",
              caseId,
              causeOnMessageId,
              diy: tab,
            })}
            key={tab}
            type="tab"
            title={t(`cause.diy.tabs.${tab}`)}
            active={tab === currentTab}
            className={css({
              minWidth: "fit-content",
            })}
          />
        ))}
      </div>
    </div>
  )
}
