import {FC, ReactNode} from "react"

import {css, cva, cx} from "@styled-system/css"

import {ButtonProps} from "./buttonProps"
import {hoverAndPressedStyles} from "./hoverAndPressedStyles"
import {ButtonIcon} from "./icon"

export interface LargeProps {
  type: "large"
  state: "primary" | "secondary" | "tertiary" | "newBordered"
  title: string
  theme?: "light" | "dark"
  icon?: string | ReactNode
  alt?: string
  className?: string
}

const largeButtonStyles = cva({
  base: {
    position: "relative",
    borderRadius: 8,
    textStyle: {base: "button", desktop: "button2"},
    height: {base: 48, desktop: 36},
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    gap: 8,
  },
  variants: {
    state: {
      primary: {
        bgGradient: "linear-gradient(135deg, #00A3E0 0%, #0041C2 100%)",
        color: "white",
        borderWidth: 0,
      },
      secondary: {},
      tertiary: {
        backgroundColor: "white",
        color: "fontBlack",
        borderWidth: 0,
      },
      newBordered: {
        borderWidth: 1,
        borderColor: "rgba(1, 83, 200, 1)",
        color: "rgba(1, 83, 200, 1)",
      },
      disabled: {
        backgroundColor: "lightGrey",
        color: "darkGrey",
      },
    },
    icon: {
      true: {
        paddingY: 8,
        paddingLeft: 12,
        paddingRight: 16,
        "& svg, & img": {
          width: 18,
          height: 18,
        },
      },
      false: {
        paddingY: 10,
        paddingX: 16,
        justifyContent: "center",
      },
    },
    theme: {
      light: {},
      dark: {},
    },
  },
  compoundVariants: [
    {
      state: "secondary",
      theme: "light",
      css: {
        color: "fontBlack",
        backgroundColor: "lightGrey",
      },
    },
    {
      state: "secondary",
      theme: "dark",
      css: {
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        color: "white",
      },
    },
  ],
})

export const LargeButton: FC<LargeProps & ButtonProps> = ({
  icon,
  title,
  disabled,
  onClick,
  alt,
  state,
  theme = "light",
  className,
  ariaLabel = "",
  id,
}) => (
  <button
    disabled={disabled}
    onClick={onClick}
    className={cx(
      hoverAndPressedStyles({
        overlay:
          state === "primary" ? "white" : disabled ? "disabled" : "black",
      }),
      largeButtonStyles({
        state: disabled ? "disabled" : state,
        icon: !!icon,
        theme,
      }),
      className,
    )}
    id={id}
    aria-label={ariaLabel}
  >
    <ButtonIcon icon={icon} alt={alt} />
    <span className={css({zIndex: 2})}>{title}</span>
  </button>
)
