import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import {iconsColor} from "@frontend/design"
import check from "@frontend/design/icons/check.svg"
import copy from "@frontend/design/icons/copy.svg"
import {useTranslation} from "@frontend/i18n"
import {useCopyText} from "@frontend/utils/useCopyText"
import {css, cx} from "@styled-system/css"

interface Props {
  url: string
  theme?: "light" | "dark"
  state?: "primary" | "secondary"
  className?: string
}
export const CopyLinkButton: FC<Props> = ({
  url,
  theme = "light",
  state = "primary",
  className,
}) => {
  const t = useTranslation()
  const [isCopied, onClickToCopy] = useCopyText(url)

  return (
    <Button
      type="large"
      icon={
        <ReactSVG
          src={isCopied ? check : copy}
          className={iconsColor({color: "white"})}
        />
      }
      state={state}
      theme={theme}
      title={isCopied ? t("case.copied") : t("case.copyLink")}
      onClick={onClickToCopy}
      className={cx(
        css({
          width: "100%",
          justifyContent: "center",
          desktop: {
            width: "fit-content",
            justifyContent: "unset",
          },
          minWidth: "max-content",
        }),
        className,
      )}
    />
  )
}
