import {Dialog, Portal} from "@ark-ui/react"
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"

import {getAppRoot} from "@frontend/utils/getAppRoot"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {CloseButton} from "./close-button"

interface Props {
  title: string | ReactNode
  subtitle?: string | ReactNode
  message?: string | ReactNode
  show: boolean
  onDismiss: () => void
  width?: number
  icon?: ReactNode
  bottomSheet?: boolean
}

export const Modal: FC<PropsWithChildren<Props>> = ({
  title,
  subtitle,
  message,
  show,
  onDismiss,
  width,
  icon,
  bottomSheet,
  children,
}) => {
  // Change some state to cause one extra render on mount in case the
  // app root wasn't available yet.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHasRendered] = useState(false)
  useEffect(() => {
    setHasRendered(true)
  }, [])

  const appRoot = getAppRoot()
  const appRootRef = useRef(appRoot)
  appRootRef.current = appRoot

  if (!appRoot) {
    return
  }

  return (
    <Dialog.Root
      open={show}
      closeOnEscapeKeyDown={false}
      closeOnInteractOutside={false}
      lazyMount
      onOpenChange={({open}) => {
        if (!open) {
          onDismiss()
        }
      }}
    >
      <Portal container={appRootRef}>
        <Dialog.Backdrop
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              backgroundColor: "#000000",
              opacity: 0.5,
              zIndex: "modalBackdrop",
            }),
            bottomSheet &&
              css({
                _open: {
                  animation: {
                    base: "fadeInOverlay 0.2s",
                    desktop: "unset",
                  },
                },
                _closed: {
                  animation: {
                    base: "fadeOutOverlay 0.2s",
                    desktop: "unset",
                  },
                },
              }),
          )}
        />
        <Dialog.Positioner
          onClick={onDismiss}
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              zIndex: "modal",
              display: "flex",
              justifyContent: "center",
            }),
            bottomSheet
              ? css({alignItems: {base: "flex-end", desktop: "center"}})
              : css({alignItems: "center"}),
          )}
        >
          <Dialog.Content
            onClick={(e) => e.stopPropagation()}
            className={cx(
              css({
                backgroundColor: "white",
                width: {base: "100%", desktop: undefined},
                display: show ? "flex" : undefined,
                flexDirection: "column",
                textStyle: "body",
                maxHeight: "100%",
                paddingTop: {
                  base: 16,
                  desktop: 20,
                },
                paddingBottom: children
                  ? undefined
                  : {
                      base: 16,
                      desktop: 20,
                    },
                minWidth: 0,
              }),
              bottomSheet
                ? css({
                    borderTopRadius: 12,
                    desktop: {
                      borderBottomRadius: 12,
                    },
                    _open: {
                      animation: {
                        base: "slideFromBottom 0.2s",
                        desktop: "unset",
                      },
                    },
                    _closed: {
                      animation: {
                        base: "slideFromTop 0.2s",
                        desktop: "unset",
                      },
                    },
                  })
                : css({borderRadius: 12, marginX: 16}),
            )}
            style={{width}}
          >
            <div
              className={vstack({
                alignItems: "stretch",
                gap: {base: 16, desktop: 20},
              })}
            >
              <div
                className={hstack({
                  justifyContent: "space-between",
                  gap: {
                    base: 16,
                    desktop: 20,
                  },
                  paddingX: {
                    base: 16,
                    desktop: 20,
                  },
                  alignItems: "flex-start",
                })}
              >
                <div>
                  <div
                    className={css({
                      display: "flex",
                      gap: {
                        base: 16,
                        desktop: 20,
                      },
                      alignItems: "center",
                    })}
                  >
                    {icon}
                    <h5
                      className={css({
                        textStyle: "h5",
                        color: "fontBlack",
                      })}
                    >
                      {title}
                    </h5>
                  </div>
                  {subtitle && (
                    <div
                      className={css({
                        textStyle: "caption",
                        color: "fontGrey",
                        desktop: {
                          textStyle: "body",
                        },
                      })}
                    >
                      {subtitle}
                    </div>
                  )}
                </div>
                <CloseButton onClick={onDismiss} />
              </div>
              {message && (
                <div
                  className={css({
                    color: "fontGrey",
                    textStyle: "body",
                    paddingX: {
                      base: 16,
                      desktop: 20,
                    },
                  })}
                >
                  {message}
                </div>
              )}
            </div>
            {children && (
              <div
                className={vstack({
                  gap: {
                    base: 16,
                    desktop: 20,
                  },
                  padding: {
                    base: 16,
                    desktop: 20,
                  },
                  alignItems: "stretch",
                })}
              >
                {children}
              </div>
            )}
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
