/* eslint-disable react-refresh/only-export-components */
import {FC} from "react"
import {useNavigate} from "react-router-dom"

import {ButtonProps} from "./buttonProps"
import {IconButton, IconProps} from "./icon"
import {LargeButton, LargeProps} from "./large"
import {TabButton, TabProps} from "./tab"
import {UnstyledButton, UnstyledProps} from "./unstyled"
import {TooltipWrapper} from "../tooltip"
export {hoverAndPressedStyles} from "./hoverAndPressedStyles"
export * from "./copy-link"

export interface NavigateToProps {
  navigateTo: string
  external?: boolean
  onClick?: () => void
}

export interface OnClickProps {
  onClick: () => void
}

type Props = (LargeProps | IconProps | TabProps | UnstyledProps) &
  (NavigateToProps | OnClickProps) &
  ButtonProps

export const Button: FC<Props> = (props) => {
  const navigate = useNavigate()

  const onClick = (): void => {
    if ("navigateTo" in props) {
      if (props.type === "tab") {
        navigate(props.navigateTo, {replace: true})
      } else if (props.external) {
        window.open(props.navigateTo, "_blank")
      } else navigate(props.navigateTo)
    }

    props.onClick?.()
  }

  if (props.type === "tab") {
    return (
      <TooltipWrapper
        content={props.tooltip}
        positioning={props.tooltipPlacement}
        variant={props.tooltipVariant}
      >
        <TabButton {...props} onClick={onClick} />
      </TooltipWrapper>
    )
  }

  if (props.type === "large") {
    return (
      <TooltipWrapper
        content={props.tooltip}
        positioning={props.tooltipPlacement}
        variant={props.tooltipVariant}
      >
        <LargeButton {...props} onClick={onClick} />
      </TooltipWrapper>
    )
  }

  if (props.type === "icon") {
    return (
      <TooltipWrapper
        content={props.tooltip}
        positioning={props.tooltipPlacement}
        variant={props.tooltipVariant}
      >
        <IconButton {...props} onClick={onClick} />
      </TooltipWrapper>
    )
  }

  if (props.type === "unstyled") {
    return (
      <TooltipWrapper
        content={props.tooltip}
        positioning={props.tooltipPlacement}
        variant={props.tooltipVariant}
      >
        <UnstyledButton {...props} onClick={onClick} />
      </TooltipWrapper>
    )
  }
}
