import {FC} from "react"

import playButton from "@frontend/design/icons/play-button.svg"
import {MessageMedia, isMessageVideo} from "@ri2/db/client"
import {css} from "@styled-system/css"

interface Props {
  media: MessageMedia
  onClick: () => void
}

export const Media: FC<Props> = ({media, onClick}) => {
  const isVideo = isMessageVideo(media)
  const imageUrl = isVideo ? media.thumbnailUrl : media.url

  return (
    <div
      className={css({
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: 8,
      })}
      onClick={onClick}
    >
      {isVideo && (
        <img
          src={playButton}
          alt={""}
          className={css({
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
            zIndex: 1,
          })}
        />
      )}
      <img
        src={imageUrl}
        alt={media.title ?? ""}
        className={css({
          height: 180,
          width: "auto",
          cursor: "pointer",
        })}
      />
      {media.title && (
        <div
          className={css({
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            padding: 5,
            textStyle: "caption",
            textAlign: "center",
          })}
        >
          {media.title}
        </div>
      )}
    </div>
  )
}
