import {FC} from "react"

import {DesktopRemediations} from "./desktop-remediations"
import {LargeDesktopRemediations} from "./large-desktop-remediations"
import {MobileRemediations} from "./mobile-remediations"
import {RemediationsProps} from "./types"

export {type RemediationTypeProps} from "./remediation"

export const Remediations: FC<RemediationsProps> = (props) => (
  <>
    <LargeDesktopRemediations {...props} />
    <MobileRemediations {...props} />
    <DesktopRemediations {...props} />
  </>
)
