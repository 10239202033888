import {Wrapper} from "@googlemaps/react-wrapper"
import {FC, useState} from "react"

import {InformationBanner} from "@frontend/components/ui/information-banner"
import {mainGridIfDesktop} from "@frontend/design"
import {GOOGLE_MAPS_API_KEY} from "@frontend/env"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

import {AddressInput} from "./components/input"
import {Map} from "./components/map"
import {Results} from "./components/results"
import {DesktopBackHeader} from "../components/desktop-back-header"
import {MobileBackHeader} from "../components/mobile-back-header"
import {Title} from "../components/title"
import {useDataFromParams} from "../params"
import {useSetBreadcrumbs} from "../util/useSetBreadcrumbs"

const DifmPage: FC = () => {
  const {
    causeOnMessageId,
    caseId,
    remediationPageData: {remediation},
  } = useDataFromParams()

  useSetBreadcrumbs({caseId, causeOnMessageId})

  const t = useTranslation()
  const [input, setInput] = useState("")
  const [results, setResults] = useState<
    google.maps.places.PlaceResult[] | null
  >(null)

  const resetResults = (): void => {
    setResults(null)
  }

  const isRecommended = remediation.recommendation.type === "difmRemediation"

  return (
    <div
      className={cx(
        css({
          width: "100%",
          height: "100%",
          gridTemplateRows: "auto 1fr",
          desktop: {
            marginX: 16,
            marginY: 6,
          },
        }),
        mainGridIfDesktop(),
      )}
    >
      <MobileBackHeader remediation={remediation} feedbackType="DIFM" />
      <DesktopBackHeader
        title={t("cause.difm.title")}
        remediation={remediation}
        feedbackType="DIFM"
        className={css({
          gridColumn: "1 / 13",
        })}
        headerType="difm"
        goBack
      />
      <Wrapper apiKey={GOOGLE_MAPS_API_KEY} libraries={["places"]}>
        <div
          className={css({
            flex: 1,
            display: "flex",
            height: "100%",
            flexDirection: "column",
            gridRow: "2",
            gridColumn: {
              desktop: "1 / 6",
            },
          })}
        >
          <Title
            isRecommended={isRecommended}
            title={t("cause.difm.title")}
            className={css({marginBottom: 24})}
          />
          <div
            className={vstack({
              marginBottom: 23,
              paddingLeft: 16,
              paddingRight: 16,
              desktop: {
                paddingLeft: 0,
                paddingRight: 44,
              },
              justifyContent: "flex-start",
              position: "relative",
              gap: 8,
              height: "100%",
            })}
          >
            <AddressInput setInput={setInput} resetResults={resetResults} />
            {results?.length ? (
              <Results places={results} />
            ) : results === null ? (
              <InformationBanner
                content={t("cause.difm.information")}
                variant="blue"
              />
            ) : (
              <InformationBanner
                variant="red"
                content={t("cause.difm.noResults")}
              />
            )}
          </div>
        </div>
        <div
          className={css({
            gridColumn: {
              desktop: "6 / 13",
            },
            gridRow: {
              desktop: "2",
            },
            display: {
              base: "none",
              desktop: "flex",
            },
            flexDirection: "column",
            width: "100%",
            height: "100%",
            paddingX: {
              base: 16,
              desktop: 0,
            },
          })}
        >
          <Map zipCode={input} zoom={input ? 11 : 4} setResults={setResults} />
        </div>
      </Wrapper>
    </div>
  )
}

export default DifmPage
