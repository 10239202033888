import React, {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import chevronWhite from "@frontend/design/icons/chevron-white.svg"
import {MessageMedia, isMessageVideo} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

const DISTANCE_FROM_TOP_MOBILE = 190
const EDGE_BUTTON_CONTAINERS_WIDTH = 80

export const Content: FC<{
  onShowpreviousImage: () => void
  onShowNextMedia: () => void
  currentMediaIndex: number
  mediaItems: MessageMedia[]
  isOpen: boolean
}> = ({
  onShowpreviousImage,
  onShowNextMedia,
  currentMediaIndex,
  mediaItems,
  isOpen,
}) => (
  <div
    className={hstack({
      gap: 0,
      height: "100%",
      flexShrink: 1,
      minHeight: 0,
      position: "relative",
    })}
  >
    <div
      className={css({
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(12, 1fr)",
        gridAutoRows: "minmax(auto, 100%)",
        gap: 20,
        alignItems: {
          base: "flex-start",
          desktop: "center",
        },
      })}
    >
      <div
        className={vstack({
          height: "min-content",
          minWidth: EDGE_BUTTON_CONTAINERS_WIDTH,
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          left: 0,
          top: 190,
          desktop: {position: "unset", height: "100%", gridColumn: "2 / 3"},
        })}
      >
        <Button
          type="icon"
          background="none"
          alt=""
          onClick={onShowpreviousImage}
          icon={<ReactSVG src={chevronWhite} />}
          className={css({
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(15px)",
            desktop: {
              backgroundColor: "rgba(255, 255, 255, 0.15)",
            },
          })}
        />
      </div>
      {mediaItems.map((media, index) => (
        <React.Fragment key={index}>
          <div
            className={vstack({
              alignItems: "center",
              justifyContent: "flex-start",
              maxHeight: "100%",
              overflow: "hidden",
              gridColumn: {
                base: " 1 / 13",
                desktop: "3 / 11",
              },
              marginTop: {
                base: 87,
                desktop: 0,
              },
            })}
            style={{
              display: index === currentMediaIndex ? "flex" : "none",
            }}
          >
            <span
              className={css({
                textStyle: "body",
                color: "white",
                marginBottom: 24,
              })}
            >
              {media.title || ""}
            </span>
            {isMessageVideo(media) ? (
              index === currentMediaIndex &&
              isOpen && (
                <iframe
                  src={media.url}
                  className={cx(
                    css({
                      aspectRatio: "16 / 9",
                      width: {
                        base: "75%",
                        desktop: "100%",
                      },
                    }),
                  )}
                />
              )
            ) : (
              <img
                src={media.url}
                className={css({
                  width: "100%",
                  height: "calc(100% - 48px)",
                  objectFit: "contain",
                  maxWidth: {
                    base: 375,
                    desktop: "100%",
                  },
                  borderRadius: {
                    base: 0,
                    desktop: 12,
                  },
                })}
              />
            )}
          </div>
        </React.Fragment>
      ))}

      <div
        className={vstack({
          height: "auto",
          minWidth: EDGE_BUTTON_CONTAINERS_WIDTH,
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          right: 0,
          top: DISTANCE_FROM_TOP_MOBILE,
          desktop: {position: "unset", height: "100%", gridColumn: "11 / 12"},
        })}
      >
        <Button
          type="icon"
          background="none"
          alt=""
          icon={
            <ReactSVG
              src={chevronWhite}
              className={css({transform: "rotateY(180deg)"})}
            />
          }
          onClick={onShowNextMedia}
          className={css({
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(15px)",

            desktop: {
              backdropFilter: "unset",
              backgroundColor: "rgba(255, 255, 255, 0.15)",
            },
          })}
        />
      </div>
    </div>
  </div>
)
