import {FC, ReactNode} from "react"

import {Button, hoverAndPressedStyles} from "@frontend/components"
import {
  aiBlueGradientBackground,
  aiRedGradientBackground,
} from "@frontend/design"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

type ColorVariants = "blue" | "red"

interface SelectableButtonProps {
  badge?: ReactNode
  title?: string
  variant?: ColorVariants
  selected?: boolean
  rounded?: boolean
  onClick?: () => void
  className?: string
}

export const SelectableButton: FC<SelectableButtonProps> = ({
  selected,
  onClick = (): null => null,
  rounded,
  variant = "blue",
  className,
  title,
  badge,
}) => {
  const backgroundStyle = ((): string => {
    if (selected) {
      return variant === "blue"
        ? aiBlueGradientBackground()
        : aiRedGradientBackground()
    }

    return css({backgroundColor: "lightGrey"})
  })()

  const innerElementsColor: (variant: ColorVariants) => string = (variant) => {
    if (variant === "blue") {
      return css({
        "& > div": {
          color: "lightBlue",
        },
      })
    }
    return css({
      "& > div": {
        color: "#FF5D6F",
      },
    })
  }
  return (
    <Button
      type="unstyled"
      className={cx(
        css({
          boxSizing: "border-box",
          minWidth: "min-content",
          padding: "10px 6px 10px 12px",
          height: 32,
          textStyle: "button2",
          color: selected ? "white" : "fontBlack",
          borderRadius: rounded ? 16 : 10,
        }),
        hstack({gap: 8, justifyContent: "center"}),
        backgroundStyle,
        selected && innerElementsColor(variant),
        selected && hoverAndPressedStyles({overlay: "white"}),
        !selected && hoverAndPressedStyles({overlay: "black"}),
        className,
      )}
      onClick={onClick}
    >
      {title}
      {badge}
    </Button>
  )
}

interface BadgeProps {
  count: number
  rounded?: boolean
}

export const Badge: FC<BadgeProps> = ({count, rounded}) => (
  <div
    className={cx(
      css({
        background: "white",
        padding: 4,
        boxSizing: "border-box",
        height: 20,
        width: 30,
        fontSize: 12,
        borderRadius: rounded ? 10 : 6,
      }),
      hstack({justifyContent: "center"}),
    )}
  >
    {count}
  </div>
)
