import {FC, ReactNode} from "react"

import {cva, cx} from "@styled-system/css"

import {ButtonProps} from "./buttonProps"
import {hoverAndPressedStyles} from "./hoverAndPressedStyles"

export interface IconProps {
  type: "icon"
  icon: string | ReactNode
  alt: string
  borders?: "squared" | "rounded"
  background?:
    | "default"
    | "none"
    | "blue"
    | "white"
    | "blueGradient"
    | "darkBlue"
  disabledHover?: boolean
  className?: string
}

const iconButtonStyles = cva({
  base: {
    width: 44,
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    padding: 6,
  },
  variants: {
    background: {
      default: {
        backgroundColor: "lightGrey",
      },
      blue: {
        backgroundColor: "bgBlue",
        borderRadius: 8,
      },
      darkBlue: {
        backgroundColor: "blue",
        borderRadius: 8,
      },
      blueGradient: {
        bgGradient: "linear-gradient(135deg, #00A3E0 0%, #0041C2 100%)",
        borderRadius: 8,
      },
      white: {
        backgroundColor: "white",
      },
      none: {},
    },
    borders: {
      squared: {
        borderRadius: 8,
      },
      rounded: {
        borderRadius: 18,
      },
    },
  },
})

export const IconButton: FC<IconProps & ButtonProps> = ({
  className,
  disabled,
  onClick,
  icon,
  alt,
  background = "default",
  borders = "rounded",
  disabledHover,
  id,
  ariaLabel = "",
}) => (
  <button
    className={cx(
      !disabledHover &&
        hoverAndPressedStyles({
          overlay:
            background === "blue" || background === "blueGradient"
              ? "white"
              : disabled
                ? "disabled"
                : "black",
        }),
      iconButtonStyles({
        background,
        borders,
      }),
      className,
    )}
    disabled={disabled}
    onClick={onClick}
    id={id}
    aria-label={ariaLabel || alt}
  >
    <ButtonIcon icon={icon} alt={alt} />
  </button>
)

export const ButtonIcon: FC<{icon: string | ReactNode; alt?: string}> = ({
  icon,
  alt,
}) => (
  <>
    {icon && (typeof icon === "string" ? <img src={icon} alt={alt} /> : icon)}
  </>
)
