import {FC} from "react"

import {Button} from "@frontend/components"
import ArrowDown from "@frontend/design/icons/arrow-down.svg"
import {css} from "@styled-system/css"

export const GoToBottom: FC<{
  scrollToBottom: () => void
}> = ({scrollToBottom}) => (
  <Button
    type="icon"
    alt=""
    background="white"
    borders="squared"
    icon={ArrowDown}
    onClick={scrollToBottom}
    className={css({
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "lineGrey",
      boxShadow: {
        desktop:
          "0px 4px 8px -2px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.06)",
      },
    })}
  />
)
