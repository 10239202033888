import {FC, ReactNode} from "react"

import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Logo} from "./logo"

type Variant = "red" | "blue" | "white" | "whitePrimary"

interface Props {
  content: string | ReactNode
  variant?: Variant
  transparent?: boolean
  className?: string
}

export const InformationBanner: FC<Props> = ({
  content,
  variant = "white",
  transparent,
  className,
}) => {
  const colorVariants: Record<
    Variant,
    {colors: string; transparentColors?: string}
  > = {
    red: {
      colors: css({backgroundColor: "bgRed", color: "fontRed"}),
    },
    blue: {
      colors: css({backgroundColor: "bgBlue", color: "lightBlue"}),
      transparentColors: css({
        backgroundColor: "rgba(231, 240, 251, 0.1)",
        color: "white",
      }),
    },
    white: {
      colors: css({
        backgroundColor: "white",
        color: "fontBlack",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "lineGrey",
      }),
    },
    whitePrimary: {
      colors: css({
        backgroundColor: "white",
        color: "lightBlue",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "primary",
      }),
    },
  }

  return (
    <div
      className={cx(
        hstack({
          gap: 16,
          width: "100%",
          padding: 16,
          borderRadius: 16,
          maxWidth: "100%",
        }),
        transparent && colorVariants[variant].transparentColors
          ? colorVariants[variant].transparentColors
          : colorVariants[variant].colors,
        className,
      )}
    >
      <Logo
        size="small"
        variant={
          variant === "white" || variant === "whitePrimary" ? "blue" : variant
        }
        className={css({
          marginY: 4,
          alignSelf: "flex-start",
        })}
      />
      <p
        className={css({
          textStyle: "caption",
          alignSelf: "center",
          wordBreak: "break-word",
        })}
      >
        {content}
      </p>
    </div>
  )
}
