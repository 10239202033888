import {FC, PropsWithChildren} from "react"

import {useNavigateUpBreadcrumbs} from "@frontend/app-container/context"
import {Button} from "@frontend/components"
import chevronLeftIcon from "@frontend/design/icons/chevron-left.svg"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

interface Props {
  className?: string
}

export const Sidebar: FC<PropsWithChildren<Props>> = ({
  className,
  children,
}) => (
  <div
    className={cx(
      vstack({
        alignItems: "stretch",
        gap: 16,
        paddingY: 24,
        paddingX: 32,
        backgroundColor: "rgba(8, 32, 101, 1)",
        largeDesktop: {
          overflowY: "auto",
        },
        largeDesktopDown: {
          overflowY: "unset",
        },
      }),
      className,
    )}
  >
    <div
      className={css({
        flexGrow: 0,
        flexShrink: 0,
      })}
    >
      <BackButton />
    </div>
    <div className={css({height: 1})} />
    <div>{children}</div>
  </div>
)

const BackButton: FC = () => {
  const onBack = useNavigateUpBreadcrumbs()
  const t = useTranslation()

  return (
    <Button
      type="unstyled"
      onClick={onBack}
      className={hstack({
        paddingY: 8,
        gap: 8,
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "15.73px",
        letterSpacing: "-0.02em",
        textTransform: "uppercase",
        color: "white",
      })}
    >
      <img src={chevronLeftIcon} />
      {t("general.back")}
    </Button>
  )
}
