import {ComponentProps, FC} from "react"

import {Modal} from "@frontend/components"
import type {DiyVideo, MergedRemediation} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

import {Step} from "../../components/step"

type Props = {
  video: DiyVideo
  diyRemediation: NonNullable<MergedRemediation["diyRemediation"]>
} & Pick<ComponentProps<typeof Modal>, "show" | "onDismiss">

export const VideoModal: FC<Props> = ({
  video: {name, url},
  diyRemediation: {steps},
  show,
  onDismiss,
}) => {
  // TODO
  const showSteps = false

  return (
    <Modal title={name} show={show} onDismiss={onDismiss} width={897}>
      <div
        className={css({
          display: "flex",
          flexDirection: "row",
        })}
      >
        <iframe
          src={url}
          className={cx(
            css({
              aspectRatio: "16 / 9",
              width: showSteps ? "67%" : "100%",
            }),
          )}
        />
        {showSteps && (
          <div
            className={css({
              width: "33%",
              aspectRatio: "20/9",
              display: "flex",
              flexDirection: "column",
              gap: 48,
              overflowY: "auto",
              marginRight: 12,
              paddingLeft: 20,
              paddingRight: 12,
              paddingBottom: 24,
            })}
          >
            {steps.steps.map((step, index) => (
              <Step step={step} index={index} key={index} />
            ))}
          </div>
        )}
      </div>
    </Modal>
  )
}
