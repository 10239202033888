import {assertOrNotFound} from "@frontend/utils/notFound"
import {useSuspenseQueries} from "@frontend/utils/trpc"
import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"
import {useTypedParams} from "@frontend/utils/useTypedParams"
import type {
  EnrichedProductInfo,
  MergedRemediationWithHashIdAndFeedback,
} from "@ri2/db/client"

import {CauseIdParams} from "../params"

export const useDataFromParams = (): {
  causeOnMessageId: number
  caseId: string
  remediation: Pick<
    MergedRemediationWithHashIdAndFeedback,
    "hashId" | "cause" | "feedback" | "partsByPartType" | "recommendation"
  > & {
    diyRemediation: NonNullable<
      MergedRemediationWithHashIdAndFeedback["diyRemediation"]
    >
  }
  enrichedProductInfo: EnrichedProductInfo
} => {
  const {causeOnMessageId, caseId} = useTypedParams(CauseIdParams)

  const [{remediation, enrichedProductInfo}] = useSuspenseQueries((trpc) => [
    trpc.remediationPageData({
      causeOnMessageId,
    }),
  ])
  assertOrNotFound(remediation.diyRemediation)

  return useStabilizedValue({
    causeOnMessageId,
    caseId,
    remediation: {...remediation, diyRemediation: remediation.diyRemediation},
    enrichedProductInfo,
  })
}
