import {TFunction} from "i18next"
import {FC, useRef} from "react"

import {InformationBanner} from "@frontend/components/ui/information-banner"
import {useTranslation} from "@frontend/i18n"
import type {
  EnrichedProductInfo,
  MergedDiyRemediation,
  MergedRemediation,
  Source,
} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

import {Step} from "./step"
import {StepItem} from "./step-item"

const idForStep = (index: number): string => `step-${index}`

interface Props {
  cause: MergedRemediation["cause"]
  steps: MergedDiyRemediation["steps"]
  enrichedProductInfo: EnrichedProductInfo
  className?: string
}

export const Steps: FC<Props> = ({
  cause,
  steps,
  enrichedProductInfo,
  className,
}) => {
  const t = useTranslation()

  const containerRef = useRef<HTMLDivElement>(null)

  if (steps.steps.length === 0) {
    return (
      <div className={cx(css({}), className)}>
        <InformationBanner
          content={t("cause.diy.empty.guide")}
          variant="blue"
        />
      </div>
    )
  }

  return (
    <>
      <div
        className={css({
          flexDirection: "row",
          gap: 20,
          overflow: "hidden",
          display: {
            largeDesktop: "flex",
            base: "none",
          },
          minWidth: 200,
        })}
      >
        <ul
          className={css({
            overflowX: "hidden",
            maxWidth: "100%",
            width: "100%",
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            gap: 4,
            overflowY: "auto",
            paddingBottom: 24,
            maxHeight: "calc(100vh - 300px)",
          })}
        >
          {steps.steps.map((step, index) => (
            <li key={index}>
              <StepItem
                step={step}
                index={index}
                idToScrollTo={idForStep(index)}
                containerRef={containerRef}
                key={index}
              />
            </li>
          ))}
        </ul>
      </div>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: {
            base: 32,
            largeDesktop: 48,
          },
          overflowY: {
            base: undefined,
            largeDesktop: "auto",
          },
          paddingBottom: 24,
          largeDesktop: {
            paddingRight: 12,
            overflowY: "auto",
          },
        })}
        ref={containerRef}
      >
        {(steps.source.type === "productType" ||
          steps.source.type === "brand") && (
          <InformationBanner
            content={informationBannerContent(
              cause,
              steps.source,
              enrichedProductInfo,
              t,
            )}
            variant={"blue"}
          />
        )}
        {steps.steps.map((step, index) => (
          <Step step={step} index={index} id={idForStep(index)} key={index} />
        ))}
      </div>
    </>
  )
}

const informationBannerContent = (
  cause: MergedRemediation["cause"],
  source: Source,
  enrichedProductInfo: EnrichedProductInfo,
  t: TFunction,
): string | null => {
  if (source.type === "match") {
    return null
  }

  if (source.type === "productType" && source.generic) {
    return t(
      enrichedProductInfo.modelNumber
        ? "cause.diy.guide.dataQuality.generic.withModelNumber"
        : "cause.diy.guide.dataQuality.generic.withoutModelNumber",
      {
        productType: cause.productType.name.toLowerCase(),
      },
    )
  }

  return t(
    enrichedProductInfo.modelNumber
      ? "cause.diy.guide.dataQuality.borrow"
      : "cause.diy.guide.dataQuality.borrow",
  )
}
