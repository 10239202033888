import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import {iconsColor} from "@frontend/design"
import download from "@frontend/design/icons/download.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

export const DownloadButton: FC<{imageUrl: string}> = ({imageUrl}) => {
  const t = useTranslation()

  return (
    <Button
      type="large"
      icon={
        <ReactSVG src={download} className={iconsColor({color: "white"})} />
      }
      state="secondary"
      theme="dark"
      title={t("conversation.imageGallery.downloadImage")}
      navigateTo={imageUrl}
      external={true}
      className={css({
        width: "100%",
        justifyContent: "center",
        desktop: {
          width: "fit-content",
          justifyContent: "unset",
        },
      })}
    />
  )
}
