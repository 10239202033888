import {TFunction} from "i18next"
import {FC} from "react"
import {useSearchParams} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {Tag} from "@frontend/components/tag"
import {Button} from "@frontend/components/ui"
import chevron from "@frontend/design/icons/chevron-down.svg"
import currencyCircleDollarIcon from "@frontend/design/icons/currency-circle-dollar.svg"
import deliveryIcon from "@frontend/design/icons/delivery.svg"
import mapIcon from "@frontend/design/icons/map.svg"
import recycleIcon from "@frontend/design/icons/recycle.svg"
import skillsIcon from "@frontend/design/icons/skills.svg"
import timeIcon from "@frontend/design/icons/time.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {isDiyRemediationUnavailable} from "@frontend/utils/getRecommendedRemediation"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {MergedRemediation} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Price} from "../price"

const doItForMeConsiderItems = (
  {diyRemediation}: Pick<MergedRemediation, "diyRemediation">,
  t: TFunction,
): (ConsiderItemProps | undefined)[] => {
  const skillLevelRequired = diyRemediation?.skillLevel

  return [
    {
      icon: mapIcon,
      label: t("cause.nextStep.doItForMe.localAvailability"),
    },
    skillLevelRequired && {
      icon: skillsIcon,
      label: t("cause.nextStep.doItForMe.skillLevel", {
        skillLevel: t(`cause.nextStep.skillLevels.${skillLevelRequired}`),
      }),
    },
    {
      icon: timeIcon,
      label: t("cause.nextStep.doItForMe.numberOfTechnicianVisits"),
    },
  ]
}

const diyConsiderItems = (
  {
    partsByPartType,
    diyRemediation,
  }: Pick<MergedRemediation, "partsByPartType" | "diyRemediation">,
  t: TFunction,
): (ConsiderItemProps | undefined)[] => {
  if (partsByPartType.partTypes.length > 0) {
    const skillLevelRequired = diyRemediation?.skillLevel

    return [
      {
        icon: deliveryIcon,
        label: t("cause.nextStep.diy.shipping"),
      },
      {
        icon: timeIcon,
        label: t("cause.nextStep.diy.age"),
      },
      {
        icon: skillsIcon,
        label: skillLevelRequired
          ? t("cause.nextStep.diy.difficulty", {
              skillLevel: t(`cause.nextStep.skillLevels.${skillLevelRequired}`),
            })
          : t("cause.nextStep.diy.difficultyGeneric"),
      },
    ]
  }

  return [
    {
      icon: timeIcon,
      label: t("cause.nextStep.diy.repairTime"),
    },
    {
      icon: skillsIcon,
      label: t("cause.nextStep.diy.difficultyGeneric"),
    },
  ]
}

const replaceConsiderItems = (
  t: TFunction,
): (ConsiderItemProps | undefined)[] => [
  {
    icon: timeIcon,
    label: t("cause.nextStep.replace.installationTimeframe"),
  },
  {
    icon: currencyCircleDollarIcon,
    label: t("cause.nextStep.replace.installationFees"),
  },
  {
    icon: recycleIcon,
    label: t("cause.nextStep.replace.disposal"),
  },
]

interface ConsiderItemProps {
  label: string
  icon: string
  unavailable?: boolean
}

const ConsiderItem: FC<ConsiderItemProps> = ({label, icon, unavailable}) => (
  <div
    className={css({
      marginLeft: -4,
      minHeight: 24,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
    })}
  >
    <div
      className={css({
        height: 24,
        width: 32,
        minWidth: 32,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <img
        src={icon}
        alt=""
        className={css(
          unavailable
            ? {
                filter: "grayscale(100%)",
                opacity: 0.4,
              }
            : {},
        )}
      />
    </div>
    <div
      className={css({
        textStyle: "body2",
        color: unavailable ? "darkGrey" : "fontGrey",
      })}
    >
      {label}
    </div>
  </div>
)

interface OtherProps {
  caseId: string
  causeOnMessageId: number
  showActionButton?: boolean
  recommended?: boolean
  className?: string
}

export type RemediationTypeProps =
  | {
      type: "replace"
      remediation: Pick<
        MergedRemediation,
        "cause" | "partsByPartType" | "replaceCost"
      >
    }
  | {
      type: "doItForMe"
      remediation: Pick<
        MergedRemediation,
        "cause" | "partsByPartType" | "diyRemediation" | "difmCost"
      >
    }
  | {
      type: "doItYourself"
      remediation: Pick<
        MergedRemediation,
        "cause" | "partsByPartType" | "diyRemediation" | "diyCost"
      >
    }

type Props = RemediationTypeProps & OtherProps

export const Remediation: FC<Props> = ({
  caseId,
  causeOnMessageId,
  showActionButton,
  recommended,
  className,
  ...remediationProps
}) => {
  const navigateTo =
    remediationProps.type === "doItYourself"
      ? makePath({
          name: "cases",
          caseId,
          causeOnMessageId,
          diy: true,
        })
      : remediationProps.type === "doItForMe"
        ? makePath({
            name: "cases",
            caseId,
            causeOnMessageId,
            difm: true,
          })
        : remediationProps.remediation.cause.productTypeMetadata.replaceUrl

  const unavailable =
    remediationProps.type === "doItYourself" &&
    isDiyRemediationUnavailable(remediationProps.remediation)

  const t = useTranslation()

  const {
    setFalse: collapseConsiderationItems,
    setTrue: expandConsiderationItems,
    state: isExpandedConsiderationItems,
  } = useBooleanState(false)

  const cost =
    remediationProps.type === "doItForMe"
      ? remediationProps.remediation.difmCost
      : remediationProps.type === "replace"
        ? remediationProps.remediation.replaceCost
        : remediationProps.remediation.diyCost

  const considerItems =
    remediationProps.type === "doItForMe"
      ? doItForMeConsiderItems(remediationProps.remediation, t)
      : remediationProps.type === "replace"
        ? replaceConsiderItems(t)
        : diyConsiderItems(remediationProps.remediation, t)
  const filteredConsiderItems = considerItems.filter(
    (labelAndIcon): labelAndIcon is ConsiderItemProps => !!labelAndIcon,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()

  const onReplace = (): void => {
    setSearchParams({replace: "true"})
  }

  return (
    <div
      className={cx(
        css(
          {
            borderRadius: 12,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(250, 250, 250, 1)",
            borderWidth: 1,
            borderColor: "rgba(202, 200, 200, 1)",
            padding: 20,

            largeDesktopOnly: {
              "@media (max-height: 800px)": {
                paddingBottom: 0,
              },
            },

            desktop: {
              overflowY: "auto",
            },
          },
          recommended && {
            backgroundColor: "white",
            boxShadow:
              "0px 3px 3px 0px rgba(28, 37, 72, 0.06), 0px 9px 20px 0px rgba(28, 37, 72, 0.14)",
          },
        ),
        className,
      )}
    >
      <div
        className={vstack({
          flex: 1,
          alignItems: "stretch",
          gap: {
            base: 16,
            largeDesktop: 36,
          },
          "@media (max-height: 800px)": {
            largeDesktop: {
              gap: 16,
            },
          },
        })}
      >
        <div
          className={hstack({
            justifyContent: "space-between",
            minHeight: 24,
            flexWrap: "wrap",
          })}
        >
          <span
            className={css({
              textStyle: "h5",
              color: unavailable ? "darkGrey" : "fontBlack",
              flexShrink: 0,
            })}
          >
            {t(`cause.nextStep.titles.${remediationProps.type}`) as string}
          </span>
          {!unavailable && recommended && (
            <Tag
              title={t("cause.nextStep.recommended")}
              className={css({flexShrink: 0})}
            />
          )}
          {unavailable && (
            <Tag title={t("cause.nextStep.unavailable")} colorScheme="blue" />
          )}
        </div>
        <Price cost={cost} type={remediationProps.type} />
        <Button
          type="unstyled"
          onClick={
            isExpandedConsiderationItems
              ? collapseConsiderationItems
              : expandConsiderationItems
          }
          className={hstack({
            display: "none",
            largeDesktopOnly: {
              "@media (max-height: 800px)": {display: "flex"},
            },
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 8,
          })}
        >
          <ReactSVG
            src={chevron}
            className={css({
              transform: isExpandedConsiderationItems
                ? "rotate(180deg)"
                : "rotate(0deg)",
            })}
          />
          <h3
            className={css({
              fontSize: 16,
              fontWeight: "700",
              lineHeight: "24px",
            })}
          >
            {t("cause.nextStep.moreToConsider")}
          </h3>
        </Button>
        <div
          className={cx(
            css({
              display: "flex",
              flexDirection: "column",
              gap: 16,
              marginBottom: recommended ? 80 : 0,
              desktopDown: {
                display: "none",
              },
            }),
            isExpandedConsiderationItems
              ? css({"@media (max-height: 800px)": {display: "flex"}})
              : css({
                  "@media (max-height: 800px)": {
                    display: "none",
                    position: "absolute",
                  },
                }),
          )}
        >
          <h3
            className={css({
              textStyle: "h5",
              color: unavailable ? "darkGrey" : "fontBlack",
              "@media (max-height: 800px)": {display: "none"},
            })}
          >
            {t("cause.nextStep.moreToConsider")}
          </h3>
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              gap: 12,
            })}
          >
            {filteredConsiderItems.map(({label, icon}) => (
              <ConsiderItem
                label={label}
                icon={icon}
                key={icon}
                unavailable={unavailable}
              />
            ))}
          </div>
        </div>
        <div
          className={css(
            {
              marginTop: "auto",
              largeDesktopOnly: {
                "@media (max-height: 800px)": {
                  position: "sticky",
                  paddingBottom: 20,
                  bottom: 0,
                  backgroundColor: "white",
                },
              },
            },
            recommended
              ? {backgroundColor: "white"}
              : {backgroundColor: "rgba(250, 250, 250, 1)"},
          )}
        >
          {showActionButton && navigateTo && (
            <Button
              title={t(`cause.nextStep.action.${remediationProps.type}`)}
              id={idForActionButton(remediationProps.type)}
              type="large"
              state={recommended ? "primary" : "newBordered"}
              navigateTo={navigateTo}
              onClick={
                remediationProps.type === "replace" ? onReplace : undefined
              }
              disabled={unavailable}
              external={remediationProps.type === "replace"}
              className={css({
                marginTop: "auto",
                width: "100%",
              })}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const idForActionButton = (type: string): string => `remediation-action-${type}`
