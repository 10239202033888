import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import {FeedbackRating} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Reactions} from "../reactions"
import {Logo} from "../ui"

interface EvaluationOverallProps {
  rating?: FeedbackRating
  onRate: (rating: FeedbackRating) => void
}

export const EvaluationOverall: FC<EvaluationOverallProps> = ({
  rating,
  onRate,
}) => {
  const t = useTranslation()

  return (
    <div
      className={hstack({
        borderRadius: 12,
        borderWidth: 1,
        borderColor: "lineGrey",
        padding: 16,
        backgroundColor: "white",
        textStyle: "body",
        color: "fontBlack",
        gap: 16,
      })}
    >
      <Logo
        size="small"
        className={css({
          display: {base: "none", desktop: "block"},
        })}
      />
      <p
        className={css({
          flexGrow: 1,
        })}
      >
        {t("feedback.general.overallRating")}
      </p>
      <Reactions rating={rating} onRate={onRate} />
    </div>
  )
}
