import {FC} from "react"

import {aiRedGradientBackground} from "@frontend/design"
import electricityIcon from "@frontend/design/icons/caution-electricity.svg"
import fireIcon from "@frontend/design/icons/caution-fire.svg"
import heavyCautionIcon from "@frontend/design/icons/caution-heavy.svg"
import waterIcon from "@frontend/design/icons/caution-water.svg"
import {useTranslation} from "@frontend/i18n"
import type {DiyStepCaution} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

const ICONS: Record<DiyStepCaution, string> = {
  FIRE: fireIcon,
  ELECTRICITY: electricityIcon,
  WATER: waterIcon,
  SHARP: waterIcon,
  HEAVY: heavyCautionIcon,
  HOT: waterIcon,
  FRAGILE: waterIcon,
  SPILL: waterIcon,
}

interface Props {
  caution: DiyStepCaution
  className?: string
}

export const CautionChip: FC<Props> = ({caution, className}) => {
  const t = useTranslation()
  const name = t(`cause.diy.guide.caution.${caution}`)

  return (
    <div
      className={cx(
        css({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          borderRadius: 8,
          paddingLeft: 4,
          paddingRight: 8,
          paddingY: 8,
          backgroundColor: "bgRed",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#FF8784",
        }),
        className,
      )}
    >
      <img
        src={ICONS[caution]}
        alt=""
        className={css({height: 16, width: 16})}
      />
      <span
        className={cx(
          aiRedGradientBackground(),
          css({
            textStyle: "caption",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }),
        )}
      >
        {t("cause.diy.guide.caution.label", {name})}
      </span>
    </div>
  )
}
