import {FC} from "react"
import {useNavigate} from "react-router-dom"
import {ReactSVG} from "react-svg"

import addIcon from "@frontend/design/icons/add.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Button} from "./ui"

const COLOR = "rgba(23, 64, 187, 1)"

interface Props {
  className?: string
}

export const NewCaseButton: FC<Props> = ({className}) => {
  const navigate = useNavigate()

  const onCreateNewCase = (): void => {
    navigate(makePath())
  }

  const t = useTranslation()

  return (
    <Button
      type="unstyled"
      onClick={onCreateNewCase}
      className={cx(
        hstack({
          gap: 8,
          paddingX: 8,
          fontSize: 13,
          fontWeight: 600,
          lineHeight: "15.73px",
          letterSpacing: "-2%",
          color: COLOR,
        }),
        className,
      )}
    >
      <ReactSVG
        src={addIcon}
        className={cx(
          css({
            "& *": {
              stroke: COLOR,
            },
          }),
        )}
      />
      {t("appContainer.header.newCase")}
    </Button>
  )
}
