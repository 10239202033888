import {FC} from "react"
import {ReactSVG} from "react-svg"

import whiteStars from "@frontend/design/icons/white-stars.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

export const NoConfirmedCauses: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingY: 16,
        gap: 4,
        alignItems: "flex-start",
      })}
    >
      <ReactSVG src={whiteStars} />
      <h2
        className={css({
          fontWeight: 400,
          fontSize: 24,
          lineHeight: "30px",
          color: "white",
          letterSpacing: "-0.02em",
        })}
      >
        {t("cases.case.keepChatting")}
      </h2>
      <p
        className={css({
          fontWeight: 400,
          fontSize: 16,
          lineHeight: "20px",
          color: "lightSkyBlue",
          letterSpacing: "-0.01em",
        })}
      >
        {t("cases.case.noLikelyCausesMessage")}
      </p>
    </div>
  )
}
