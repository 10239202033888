import {SystemStyleObject} from "@pandacss/dev"
import {FC, RefObject, useRef} from "react"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import {iconsColor} from "@frontend/design"
import arrowUpIcon from "@frontend/design/icons/arrow-up.svg"
import stopIcon from "@frontend/design/icons/stop.svg"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

// Keep in sync with app/src/app/api/addHumanMessageToCase/route.ts
const MAX_MESSAGE_LENGTH = 3000

interface Props {
  value: string
  onFocus?: () => void
  onChange: (value: string) => void
  onSend: () => void
  onAbort: () => void
  disabled?: boolean
  isReceiving?: boolean
  inputRef?: RefObject<HTMLTextAreaElement>
  className?: string
}

export const InputField: FC<Props> = ({
  value,
  onFocus,
  onChange,
  onSend,
  onAbort,
  disabled,
  isReceiving,
  inputRef,
  className,
}) => {
  const t = useTranslation()
  const divRef = useRef<HTMLDivElement>(null)
  const maxWidth = (divRef.current?.clientWidth ?? 0) - 16

  // I decided to use this "hack", because changing the size of the textarea comes with some problems,
  // like the vertical centering of the placeholder and content, as keep the padding around the text

  const onFocusTextArea = (): void => {
    inputRef && inputRef.current?.focus()
  }

  const sharedTextAreaStyles: SystemStyleObject = {
    gridArea: "1 / 1 / 2 / 2",
    margin: 0,
    padding: 0,
    border: "none",
    outline: "none",
    flexGrow: 0,
    width: maxWidth,
    wordBreak: "break-word",
    backgroundColor: "none",
    appearance: "none",
    lineHeight: "20px",
    maxHeight: 100,
  }

  return (
    <div
      className={cx(
        css({
          display: "flex",
          flexDirection: "row",
          borderRadius: 12,
          backgroundColor: "#FFFFFF",
          borderColor: "royalBlue",
          borderWidth: 2,
          borderStyle: "solid",
          backdropFilter: "blur(15px)",
          boxSizing: "border-box",
          alignItems: "center",
          cursor: "text",
          padding: {
            base: "16px 24px 16px 24px",
            desktop: "20px 24px 20px 24px",
          },
          transition: "box-shadow ease-in 0.2s",
          "&:focus-within": {
            boxShadow: "0px 0px 0px 6px #CED8F3",
          },
        }),
        className,
      )}
      onClick={onFocusTextArea}
      ref={divRef}
    >
      <div
        className={css({
          display: "inline-grid",
          alignItems: "stretch",
          flexGrow: 0,
          width: "100%",
          outline: "none",
        })}
      >
        <textarea
          rows={1}
          maxLength={MAX_MESSAGE_LENGTH}
          value={value}
          placeholder={t("case.inputPlaceholder")}
          ref={inputRef}
          onFocus={onFocus}
          onChange={(event) => onChange(event.target.value)}
          onKeyDown={(event): void => {
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault()

              if (!disabled) {
                onSend()
              }
            }
          }}
          className={css(sharedTextAreaStyles, {
            resize: "none",
            overflow: "hidden",
            "&::placeholder": {
              color: "slateGray",
            },
          })}
        />
        <div
          className={css(sharedTextAreaStyles, {
            whiteSpace: "pre-wrap",
            visibility: "hidden",
          })}
        >
          {value}{" "}
        </div>
      </div>

      <div
        className={hstack({
          alignItems: "flex-end",
          height: "100%",
          marginY: 0,
          minHeight: 29,
          alignSelf: "end",
        })}
      >
        {isReceiving ? (
          <Button
            type="icon"
            background="none"
            icon={<ReactSVG src={stopIcon} />}
            onClick={onAbort}
            alt={t("conversation.abort")}
            ariaLabel={t("conversation.abort")}
            className={css({
              maxHeight: 29,
              maxWidth: 20,
            })}
          />
        ) : (
          <Button
            type="icon"
            borders="squared"
            icon={
              <ReactSVG
                src={arrowUpIcon}
                className={iconsColor({
                  color: "black",
                })}
              />
            }
            className={css({maxHeight: 29, maxWidth: 20})}
            alt={t("conversation.send")}
            ariaLabel={t("conversation.send")}
            onClick={onSend}
            disabled={disabled}
            background="none"
          />
        )}
      </div>
    </div>
  )
}
