import {AnimatePresence, motion} from "framer-motion"
import {FC} from "react"

import {CauseOnMessageWithCauseAndPart} from "@ri2/db/client"
import {css} from "@styled-system/css"

import {Cause, type CauseVariant} from "./cause"
import {CauseLikelihood} from "./types"

interface CausesListProps {
  causes: CauseOnMessageWithCauseAndPart[]
  caseId: string
  causeLikelihood: CauseLikelihood
  causeVariant?: CauseVariant
}

const SPRING = {
  type: "spring",
  damping: 25,
  stiffness: 120,
  duration: 2,
  staggerDirection: 1,
}

export const CausesList: FC<CausesListProps> = ({
  causes,
  caseId,
  causeLikelihood,
  causeVariant,
}) => (
  <ul
    className={css({
      display: "flex",
      flexDirection: "column",
    })}
  >
    {/* The key is used to stop it from animated when the cause likelihood changes, since that's just the user toggling between different lists */}
    <AnimatePresence initial={false} key={causeLikelihood}>
      {causes.map((causeOnMessage, index) => (
        <motion.li
          key={causeOnMessage.causeId}
          layout
          transition={SPRING}
          initial={{
            opacity: 0,
            y: 0,
            scale: 1,
            zIndex: causes.length - index,
          }}
          animate={{
            opacity: 1,
            y: 0,
            scale: 1,
            zIndex: causes.length - index,
          }}
          exit={{
            opacity: 0,
            y: 50,
            scale: 0.9,
            zIndex: causes.length - index,
          }}
        >
          <Cause
            caseId={caseId}
            cause={causeOnMessage}
            variant={causeVariant}
          />
        </motion.li>
      ))}
    </AnimatePresence>
  </ul>
)
