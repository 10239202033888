const tokens = {
  "colors.bgBlue": {
    "value": "#E7F0FB",
    "variable": "var(--colors-bg-blue)"
  },
  "colors.bgGreen": {
    "value": "#E5F6EB",
    "variable": "var(--colors-bg-green)"
  },
  "colors.bgRed": {
    "value": "#FFE5E8",
    "variable": "var(--colors-bg-red)"
  },
  "colors.bgYellow": {
    "value": "#FDF3DD",
    "variable": "var(--colors-bg-yellow)"
  },
  "colors.fontBlack": {
    "value": "#000000",
    "variable": "var(--colors-font-black)"
  },
  "colors.fontGreen": {
    "value": "#23B558",
    "variable": "var(--colors-font-green)"
  },
  "colors.fontGrey": {
    "value": "#757575",
    "variable": "var(--colors-font-grey)"
  },
  "colors.fontRed": {
    "value": "rgb(207, 39, 39)",
    "variable": "var(--colors-font-red)"
  },
  "colors.fontLightRed": {
    "value": "#FF4F68",
    "variable": "var(--colors-font-light-red)"
  },
  "colors.blue": {
    "value": "#0041C2",
    "variable": "var(--colors-blue)"
  },
  "colors.darkGrey": {
    "value": "rgb(200, 200, 200)",
    "variable": "var(--colors-dark-grey)"
  },
  "colors.green": {
    "value": "#0A8035",
    "variable": "var(--colors-green)"
  },
  "colors.grey": {
    "value": "#F0F0F0",
    "variable": "var(--colors-grey)"
  },
  "colors.lightBlue": {
    "value": "#126CD6",
    "variable": "var(--colors-light-blue)"
  },
  "colors.lightGrey": {
    "value": "rgb(248, 248, 248)",
    "variable": "var(--colors-light-grey)"
  },
  "colors.lineGrey": {
    "value": "#E6E6E6",
    "variable": "var(--colors-line-grey)"
  },
  "colors.turquoise": {
    "value": "rgb(0, 163, 224)",
    "variable": "var(--colors-turquoise)"
  },
  "colors.white": {
    "value": "#FFFFFF",
    "variable": "var(--colors-white)"
  },
  "colors.yellow": {
    "value": "#F5B62E",
    "variable": "var(--colors-yellow)"
  },
  "colors.darkBlue": {
    "value": "#082065",
    "variable": "var(--colors-dark-blue)"
  },
  "colors.royalBlue": {
    "value": "#1740BB",
    "variable": "var(--colors-royal-blue)"
  },
  "colors.lightSkyBlue": {
    "value": "#C0D2EB",
    "variable": "var(--colors-light-sky-blue)"
  },
  "colors.slateGray": {
    "value": "#595C60",
    "variable": "var(--colors-slate-gray)"
  },
  "zIndex.appBackdrop": {
    "value": 1000,
    "variable": "var(--z-index-app-backdrop)"
  },
  "zIndex.appModal": {
    "value": 1001,
    "variable": "var(--z-index-app-modal)"
  },
  "zIndex.sidebarBackdrop": {
    "value": 51,
    "variable": "var(--z-index-sidebar-backdrop)"
  },
  "zIndex.sidebar": {
    "value": 52,
    "variable": "var(--z-index-sidebar)"
  },
  "zIndex.modalBackdrop": {
    "value": 100,
    "variable": "var(--z-index-modal-backdrop)"
  },
  "zIndex.modal": {
    "value": 101,
    "variable": "var(--z-index-modal)"
  },
  "zIndex.splashScreen": {
    "value": 111,
    "variable": "var(--z-index-splash-screen)"
  },
  "zIndex.mobileMoreButton": {
    "value": 34,
    "variable": "var(--z-index-mobile-more-button)"
  },
  "zIndex.caseListsDisclaimer": {
    "value": 35,
    "variable": "var(--z-index-case-lists-disclaimer)"
  },
  "zIndex.mapSearchInputWrapper": {
    "value": 2,
    "variable": "var(--z-index-map-search-input-wrapper)"
  },
  "zIndex.tooltipPositioner": {
    "value": 100,
    "variable": "var(--z-index-tooltip-positioner)"
  },
  "zIndex.tooltipArrow": {
    "value": -1,
    "variable": "var(--z-index-tooltip-arrow)"
  },
  "zIndex.chatCausesSwitch": {
    "value": 50,
    "variable": "var(--z-index-chat-causes-switch)"
  },
  "breakpoints.desktop": {
    "value": "768px",
    "variable": "var(--breakpoints-desktop)"
  },
  "breakpoints.largeDesktop": {
    "value": "1280px",
    "variable": "var(--breakpoints-large-desktop)"
  },
  "sizes.breakpoint-desktop": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-desktop)"
  },
  "sizes.breakpoint-largeDesktop": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-large-desktop)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar