import {FC} from "react"
import {ReactSVG} from "react-svg"

import {aiBlueGradientBackground, iconsColor} from "@frontend/design"
import closeIcon from "@frontend/design/icons/close.svg"
import {css, cx} from "@styled-system/css"

interface Props {
  type: "chosen" | "unchosen" | "button"
  title: string
  onClick: () => void
  className?: string
}

export const Chip: FC<Props> = ({type, title, onClick, className}) => (
  <button
    onClick={onClick}
    className={cx(
      css(
        {
          overflow: "hidden",
          backgroundColor: "white",
          color: "fontGrey",
          textStyle: "button2",
          borderRadius: 18,
          borderWidth: 1,
          borderColor: "lineGrey",
        },
        type === "button" && {
          color: "fontBlack",
          cursor: "pointer",
        },
        type === "chosen" && {
          backgroundColor: "bgBlue",
          borderColor: "blue",
        },
      ),
      className,
    )}
  >
    <div
      className={cx(
        css(
          {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 6,
            paddingY: 8,
            paddingX: 12,
          },
          type === "button" && {
            paddingX: 16,
            paddingY: 8,
          },
          type === "chosen" && {
            backgroundImage:
              "linear-gradient(135deg, #00A3E0 0%, #0041C2 100%), linear-gradient(to right, #FFFFFF, #FF0000)",
            paddingRight: 8,
            color: "transparent",
            backgroundClip: "text",
          },
        ),
        type === "chosen" && aiBlueGradientBackground(),
      )}
    >
      {title}
      {type === "chosen" && (
        <ReactSVG
          src={closeIcon}
          className={cx(
            iconsColor({color: "blue"}),
            css({"& svg": {width: 16, height: 16}}),
          )}
        />
      )}
    </div>
  </button>
)
