import {FC} from "react"

import {Button} from "@frontend/components/ui"
import closeIcon from "@frontend/design/icons/close.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

interface Props {
  onClick: () => void
}

export const CloseButton: FC<Props> = ({onClick}) => {
  const t = useTranslation()

  return (
    <Button
      type="unstyled"
      onClick={onClick}
      className={css({
        padding: 12,
        marginTop: -12,
        marginRight: -12,
      })}
    >
      <img src={closeIcon} alt={t("general.close")} />
    </Button>
  )
}
