import {AnimatePresence, motion} from "framer-motion"
import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button, hoverAndPressedStyles} from "@frontend/components"
import {cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

const COLOR = "rgba(57, 77, 132, 1)"

interface Props {
  icon: string
  title: string
  onClick: () => void
  isSidebarOpen: boolean
}

export const SidebarButton: FC<Props> = ({
  icon,
  title,
  isSidebarOpen,
  onClick,
}) => (
  <div
    className={hstack({
      gap: 16,
      justifyContent: "flex-start",
      width: "100%",
      cursor: "pointer",
    })}
  >
    <Button
      type="unstyled"
      onClick={onClick}
      ariaLabel={title}
      className={hstack({
        gap: 16,
        textStyle: "button2",
        color: COLOR,
      })}
    >
      <ReactSVG
        src={icon}
        className={cx(
          vstack({
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "8px",
            backgroundColor: "bgBlue",
            width: 44,
            minWidth: 44,
            height: 44,
            padding: 8,
            "& *": {
              stroke: COLOR,
            },
          }),
          hoverAndPressedStyles({overlay: "black"}),
        )}
      />
      <AnimatePresence initial={false}>
        <motion.span
          animate={isSidebarOpen ? "open" : "collapsed"}
          variants={{
            open: {
              opacity: 1,
              transition: {duration: 0.1, delay: 0.3},
              display: "block",
            },
            collapsed: {
              opacity: 0,
              transition: {duration: 0.1},
              display: "none",
            },
          }}
        >
          {title}
        </motion.span>
      </AnimatePresence>
    </Button>
  </div>
)
