import {FC} from "react"

import {Button, ReactionsWithForm, Tag} from "@frontend/components"
import shieldBlueIcon from "@frontend/design/icons/shield-blue.svg"
import {useTranslation} from "@frontend/i18n"
import {Feedback, RecommendationReason} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

export const RecomendationBasedOn: FC<{
  reasons: RecommendationReason[]
  remediationHashId: number
  recommendation: Feedback | undefined
}> = ({reasons, remediationHashId, recommendation}) => {
  const t = useTranslation()
  return (
    <div
      className={hstack({
        width: "100%",
        justifyContent: "space-between",
      })}
    >
      <div
        className={hstack({
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 8,
          desktopDown: {
            flexWrap: "wrap",
          },
        })}
      >
        <span
          className={css({
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "24px",
            color: "#555555",
            desktopDown: {
              width: "100%",
            },
          })}
        >
          {t("cause.nextStep.recomendationBasedOn")}
        </span>
        {reasons.map((reason) => (
          <Reason
            key={reason}
            title={t(`cause.nextStep.recomendationReason.${reason}`)}
          />
        ))}
        <Button
          onClick={() => ""}
          type="unstyled"
          className={css({
            display: "none",
            fontWeight: "700",
            fontSize: 13,
            lineHeight: "24px",
            color: "#0169CE",
          })}
        >
          {t("cause.nextStep.learnMore")}
        </Button>
      </div>
      <div
        className={css({
          display: "none",
          desktop: {
            display: "block",
          },
        })}
      >
        <ReactionsWithForm
          on={{
            on: "remediation",
            mergedRemediationHashId: remediationHashId,
            type: "RECOMMENDATION",
          }}
          currentFeedback={recommendation}
          className={css({marginRight: {base: 16, desktop: 0}})}
        />
      </div>
    </div>
  )
}

const Reason: FC<{title: string}> = ({title}) => (
  <Tag
    title={title}
    icon={shieldBlueIcon}
    colorScheme="lightGrayBlue"
    className={css({alignSelf: "flex-start"})}
  />
)
