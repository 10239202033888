import {FC} from "react"
import Markdown from "react-markdown"

import {useTranslation} from "@frontend/i18n"
import type {DiyStep, DiyStepCaution} from "@ri2/db/client"
import {css} from "@styled-system/css"

import {CautionChip} from "./caution-chip"
import {getStepTitle} from "../util/get-step-title"

interface Props {
  step: DiyStep
  index: number
  id?: string
}

export const Step: FC<Props> = ({
  step,
  step: {contentMarkdown, cautions},
  index,
  id,
}) => {
  const sortedCautions: DiyStepCaution[] = cautions
  sortedCautions.sort()

  const t = useTranslation()

  return (
    <div
      id={id}
      className={css({
        display: "flex",
        flexDirection: "column",
        paddingBottom: 36,
        borderBottomWidth: 1,
        borderBottomColor: "lineGrey",
        borderBottomStyle: "solid",
        gap: {
          base: 8,
          desktop: 24,
        },
      })}
    >
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          gap: 8,
          paddingY: 6,
        })}
      >
        <h3
          className={css({
            fontFamily: "default",
            fontSize: 16,
            fontWeight: 700,
            lineHeight: "24px",
            color: "fontBlack",
            desktop: {
              fontSize: 24,
              lineHeight: "32px",
            },
          })}
        >
          {getStepTitle(step, index + 1, t)}
        </h3>
        <Markdown
          className={css({
            textStyle: "body",
            color: "fontGrey",
            "& em": {
              fontWeight: 700,
            },
          })}
        >
          {contentMarkdown}
        </Markdown>
      </div>
      <div
        className={css({
          display: "flex",
          flexDirection: "row",
          gap: 8,
          flexWrap: "wrap",
        })}
      >
        {sortedCautions.map((caution) => (
          <CautionChip
            caution={caution}
            className={css({
              flexShrink: 0,
            })}
            key={caution}
          />
        ))}
      </div>
    </div>
  )
}
