import {FC} from "react"

import {FeedbackModal} from "@frontend/app-container/feedback-modal"
import feedbackIcon from "@frontend/design/icons/feedback.svg"
import {useTranslation} from "@frontend/i18n"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Button} from "./ui"

interface FeedbackButtonProps {
  className?: string
}

export const FeedbackButton: FC<FeedbackButtonProps> = ({className}) => {
  const {
    state: isShowingFeedbackModal,
    setFalse: onHideFeedbackModal,
    setTrue: onShowFeedbackModal,
  } = useBooleanState(false)

  const t = useTranslation()

  return (
    <>
      <Button
        type="unstyled"
        onClick={onShowFeedbackModal}
        className={cx(
          hstack({
            gap: 8,
            paddingX: 8,
            fontSize: 13,
            fontWeight: 600,
            lineHeight: "15.73px",
            letterSpacing: "-2%",
            color: "rgba(89, 92, 96, 1)",
          }),
          className,
        )}
      >
        <img src={feedbackIcon} alt="" />
        {t("appContainer.header.feedback")}
      </Button>
      {isShowingFeedbackModal && (
        <FeedbackModal onClose={onHideFeedbackModal} />
      )}
    </>
  )
}
