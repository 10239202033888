import {FC, PropsWithChildren} from "react"
import {ReactSVG} from "react-svg"

import {iconsColor} from "@frontend/design"
import dislikeIcon from "@frontend/design/icons/dislike.svg"
import likeIcon from "@frontend/design/icons/like.svg"
import {useTranslation} from "@frontend/i18n"
import {cx} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

import {On} from "./types"
import {Modal} from "../modal"

export interface Props {
  onSubmitted: () => void
  onClose: () => void
  on?: On
}

export const ModalContainer: FC<PropsWithChildren<Props>> = ({
  children,
  onClose,
  on,
}) => {
  const t = useTranslation()

  const feedbackRatingTitle =
    on?.feedback?.rating === "NEGATIVE"
      ? t(`feedback.general.NEGATIVE.title`)
      : t(`feedback.general.POSITIVE.title`)

  return (
    <Modal
      title={
        on ? feedbackRatingTitle : t("feedback.general.feedbackModalTitle")
      }
      show
      message={t("feedback.general.feedbackModal")}
      onDismiss={onClose}
      width={610}
      icon={
        <div
          className={vstack({
            borderRadius: 8,
            backgroundColor: on
              ? on.feedback.rating === "POSITIVE"
                ? "bgGreen"
                : "bgRed"
              : "bgBlue",
            width: 36,
            height: 36,
            justifyContent: "center",
          })}
        >
          <ReactSVG
            src={
              on && on.feedback.rating === "NEGATIVE" ? dislikeIcon : likeIcon
            }
            className={cx(
              iconsColor({
                color: on
                  ? on.feedback.rating === "POSITIVE"
                    ? "green"
                    : "red"
                  : "lightBlue",
              }),
            )}
          />
        </div>
      }
    >
      {children}
    </Modal>
  )
}
