import {motion} from "framer-motion"
import {FC} from "react"

import {aiBlueGradientBackground} from "@frontend/design"
import loadingIcon from "@frontend/design/icons/loading.svg"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

interface Props {
  text?: string
  className?: string
}

export const Loading: FC<Props> = ({text, className}) => {
  const t = useTranslation()

  return (
    <div
      className={cx(
        vstack({
          justifyContent: "center",
          gap: 8,
          textStyle: "body",
        }),
        className,
      )}
    >
      <motion.img
        src={loadingIcon}
        alt=""
        animate={{rotate: 360}}
        transition={{ease: "linear", duration: 1, repeat: Infinity}}
        height={48}
        width={48}
      />
      <div
        className={cx(
          css({
            backgroundClip: "text",
            color: "transparent",
          }),
          aiBlueGradientBackground(),
        )}
      >
        {text ?? t("general.loading")}
      </div>
    </div>
  )
}
