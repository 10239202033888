import {FC} from "react"

import {cva, cx} from "@styled-system/css"

import {ButtonProps} from "./buttonProps"

export interface TabProps {
  type: "tab"
  title: string
  active: boolean
  className?: string
}

const tabButtonStyles = cva({
  base: {
    borderBottomWidth: 2,
    paddingBottom: 6,
    textStyle: {
      base: "body",
      desktop: "h5",
    },
    _enabled: {
      cursor: "pointer",
    },
  },
  variants: {
    active: {
      true: {
        color: "lightBlue",
        borderBottomColor: "lightBlue",
      },
      false: {
        color: {
          base: "fontGrey",
          desktop: "darkGrey",
        },
        borderBottomColor: "transparent",
      },
    },
  },
})

export const TabButton: FC<TabProps & ButtonProps> = ({
  title,
  onClick,
  disabled,
  active,
  className,
  id,
}) => (
  <button
    id={id}
    onClick={onClick}
    disabled={disabled}
    className={cx(tabButtonStyles({active}), className)}
  >
    {title}
  </button>
)
