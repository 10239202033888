import {createContext, useContext} from "react"

import {CaseTab} from "@frontend/routing"

interface MobileCaseLayoutContextValue {
  causesCount: number
  showCauses: boolean
  tab: CaseTab
  onChangeTab: (tab: CaseTab) => void
}

export const MobileCaseLayoutContext =
  createContext<MobileCaseLayoutContextValue>({
    causesCount: 0,
    showCauses: true,
    tab: "conversation",
    onChangeTab: () => {},
  })

// eslint-disable-next-line react-refresh/only-export-components
export const useMobileCaseLayoutContext = (): MobileCaseLayoutContextValue =>
  useContext(MobileCaseLayoutContext)
