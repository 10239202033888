import {FC, useState} from "react"

import {Button, Modal} from "@frontend/components"
import {InformationBanner} from "@frontend/components/ui/information-banner"
import question from "@frontend/design/icons/question.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

export const EmptyCasesList: FC = () => {
  const t = useTranslation()
  const [isShowReasonModal, setShowReasonModal] = useState(false)

  const onCloseReasonModal = (): void => setShowReasonModal(false)
  const onOpenReasonModal = (): void => setShowReasonModal(true)
  return (
    <>
      <div className={vstack({gap: 24})}>
        <InformationBanner
          content={t("appContainer.sidebar.emptyCasesMessage")}
          variant="blue"
        />
        <Button
          type="unstyled"
          onClick={onOpenReasonModal}
          className={css({
            textStyle: "caption",
            color: "fontGrey",
            textAlign: "center",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "lightGrey",
            paddingBottom: 4,
          })}
        >
          {t("appContainer.sidebar.previousCaseNotFoundMsg")}
        </Button>
      </div>

      <Modal
        title={t("appContainer.sidebar.noPreviousCasesModal.title")}
        message={t("appContainer.sidebar.noPreviousCasesModal.description")}
        show={isShowReasonModal}
        onDismiss={onCloseReasonModal}
        width={610}
        icon={
          <div
            className={css({
              borderRadius: 8,
              backgroundColor: "#E7F0FB",
              padding: 6,
            })}
          >
            <img src={question} alt="" />
          </div>
        }
      >
        <div className={hstack()}>
          <Button
            type="large"
            state="primary"
            onClick={onCloseReasonModal}
            className={css({width: 120})}
            title={t("appContainer.sidebar.noPreviousCasesModal.action")}
          />
        </div>
      </Modal>
    </>
  )
}
