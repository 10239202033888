import {FC, useState} from "react"
import {ReactSVG} from "react-svg"

import {InformationBanner} from "@frontend/components/ui/information-banner"
import blueStars from "@frontend/design/icons/blue-stars.svg"
import {useTranslation} from "@frontend/i18n"
import type {UiCauses} from "@ri2/app/server/routers/getCasePageData"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {CausesList} from "./CausesList"
import {NoConfirmedCauses} from "./NoConfirmedCauses"
import {ToggleButton} from "./ToggleButton"
import {
  CauseLikelihood,
  MORE_AND_VERY_LIKELY_CAUSES,
  RULED_OUT_CAUSES,
} from "./types"
import {ChatCausesSwitch} from "../chat-causes-switch"

const EMPTY_CAUSES: UiCauses = {
  confirmed: [],
  moreAndVeryLikely: [],
  ruledOut: [],
}

interface Props {
  caseId: string
  causes?: UiCauses
  className?: string
}

export const Causes: FC<Props> = ({
  caseId,
  causes = EMPTY_CAUSES,
  className,
}) => {
  const t = useTranslation()
  const [selected, setSelected] = useState<CauseLikelihood>(
    MORE_AND_VERY_LIKELY_CAUSES,
  )

  const setOtherCauses =
    (causeLikelihood: CauseLikelihood): (() => void) =>
    (): void => {
      setSelected(causeLikelihood)
    }

  const otherSelectedCauses = causes[selected]

  const colorVariant = {
    [MORE_AND_VERY_LIKELY_CAUSES]: "whitePrimary" as const,
    [RULED_OUT_CAUSES]: "red" as const,
  }

  const headingStyles = css({
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "20px",
    color: "white",
    letterSpacing: "-0.01em",
  })

  return (
    <div
      role="complementary"
      aria-label={t("causes.ariaLabel")}
      className={cx(
        css({
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          position: {base: "unset", desktop: "relative"},
          zIndex: 0, // to avoid children appearing on top of the sidebar
          paddingX: 8,
          paddingTop: {
            base: 36,
            desktop: 0,
          },
          maxHeight: {
            base: "101%",
            desktop: "calc(100vh - 128px)",
          },
        }),
        className,
      )}
    >
      <div
        className={css({
          marginTop: {
            desktop: 48,
            largeDesktop: 40,
          },
        })}
      >
        <h2
          className={css({
            fontWeight: 300,
            fontSize: 24,
            lineHeight: "30px",
            color: "white",
            letterSpacing: "-0.01em",
            marginBottom: 24,
          })}
        >
          {t("cases.case.nextStepsTitle")}
        </h2>
        {causes.confirmed.length > 0 ? (
          <>
            <h2
              className={cx(
                headingStyles,
                css({
                  marginBottom: 8,
                }),
              )}
            >
              {t("cases.case.likelyCauses")}
            </h2>
            <div
              className={css({
                backgroundColor: "#E9F0FA",
                borderRadius: 8,
              })}
            >
              <div
                className={hstack({
                  padding: "24px 20px 16px 20px",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: 4,
                })}
              >
                <ReactSVG src={blueStars} />
                <h2
                  className={css({
                    fontSize: 20,
                    lineHeight: "24px",
                    letterSpacing: "-0.02em",
                    color: "darkBlue",
                  })}
                >
                  {causes.confirmed.length === 1
                    ? t("cases.case.foundLikelyCause")
                    : t("cases.case.foundLikelyCauses")}
                </h2>
              </div>
              <CausesList
                causes={causes.confirmed}
                caseId={caseId}
                causeLikelihood={selected}
                causeVariant="lightBlue"
              />
            </div>
          </>
        ) : (
          <NoConfirmedCauses />
        )}
      </div>
      <div className={css({marginTop: 49})}>
        <div
          className={hstack({
            justifyContent: "space-between",
            gap: 1,
            width: "100%",
            marginBottom: 16,
            alignItems: "center",
            desktopOnly: {
              flexDirection: "column",
              gap: 4,
              alignItems: "flex-start",
            },
          })}
        >
          <h2 className={headingStyles}>{t("cases.case.unconfirmedCauses")}</h2>
          <div
            className={hstack({
              padding: 4,
              gap: 6,
              borderRadius: 10,
              backgroundColor: "#2E4999",
              width: "fit-content",
            })}
          >
            <ToggleButton
              selected={selected === MORE_AND_VERY_LIKELY_CAUSES}
              title={t("cases.case.possible")}
              onSelected={setOtherCauses(MORE_AND_VERY_LIKELY_CAUSES)}
              count={causes.moreAndVeryLikely.length}
            />
            <ToggleButton
              selected={selected === RULED_OUT_CAUSES}
              title={t("cases.case.ruledOut")}
              onSelected={setOtherCauses(RULED_OUT_CAUSES)}
              count={causes.ruledOut.length}
            />
          </div>
        </div>
        {otherSelectedCauses.length > 0 ? (
          <CausesList
            causes={otherSelectedCauses}
            caseId={caseId}
            causeLikelihood={selected}
          />
        ) : (
          <InformationBanner
            content={
              selected === MORE_AND_VERY_LIKELY_CAUSES
                ? t(`cases.case.noOtherCausesMessage.possible`)
                : t(`cases.case.noOtherCausesMessage.ruledOut`)
            }
            variant={colorVariant[selected]}
          />
        )}
        <div className={css({height: {base: 64, desktop: 0}})} />
      </div>
      <div
        className={css({
          position: "fixed",
          bottom: -1,
          width: "100vw",
          zIndex: 20,
          height: 72,
          backgroundColor: "darkBlue",
          display: {
            base: "flex",
            desktop: "none",
          },
          alignItems: "center",
        })}
      >
        <div className={css({width: 60})}></div>
        <ChatCausesSwitch
          className={css({
            alignSelf: "center",
            border: "none",
          })}
        />
        <div className={css({minWidth: 90})}></div>
      </div>
    </div>
  )
}
