import {TFunction} from "i18next"
import pluralize from "pluralize"
import {FC} from "react"

import {Tag} from "@frontend/components"
import shieldBlueIcon from "@frontend/design/icons/shield-blue.svg"
import shieldRedIcon from "@frontend/design/icons/shield-red.svg"
import shieldTransparentBlueIcon from "@frontend/design/icons/shield-transparent-blue.svg"
import shieldTransparentRedIcon from "@frontend/design/icons/shield-transparent-red.svg"
import shieldTransparentYellowIcon from "@frontend/design/icons/shield-transparent-yellow.svg"
import shieldYellowIcon from "@frontend/design/icons/shield-yellow.svg"
import {useTranslation} from "@frontend/i18n"
import {Source} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

type ColorScheme = "lightBlue" | "lightYellow" | "lightRed"

type Mode = "default" | "part"

interface Props {
  source: Source
  mode?: Mode
  transparent?: boolean
  overrideColorScheme?: ColorScheme
  overrideTitle?: string
  className?: string
}

export const DataQualityTag: FC<Props> = ({
  source,
  mode = "default",
  transparent = false,
  overrideColorScheme,
  overrideTitle,
  className,
}) => {
  const t = useTranslation()

  const title = overrideTitle ?? getTitleFromSource(source, mode, t)

  const colorScheme =
    overrideColorScheme ?? COLOR_SCHEMES_FOR_SOURCE_TYPES[source.type]

  return (
    <Tag
      title={title}
      icon={
        (transparent
          ? TRANSPARENT_ICONS_FOR_COLOR_SCHEMES
          : ICONS_FOR_COLOR_SCHEMES)[colorScheme]
      }
      colorScheme={colorScheme}
      transparent={transparent}
      className={cx(css({alignSelf: "flex-start"}), className)}
    />
  )
}

const getTitleFromSource = (
  source: Source,
  mode: Mode,
  t: TFunction,
): string => {
  switch (source.type) {
    case "match":
      return mode === "part"
        ? t("cause.diy.dataQuality.source.matchPart")
        : t("cause.diy.dataQuality.source.match")
    case "brand":
      return t("cause.diy.dataQuality.source.brand", {
        brandName: source.brandName,
        productType: pluralize(source.productType.toLowerCase()),
      })
    case "productType":
      return t("cause.diy.dataQuality.source.productType")
  }
}

const COLOR_SCHEMES_FOR_SOURCE_TYPES: Record<Source["type"], ColorScheme> = {
  match: "lightBlue",
  brand: "lightYellow",
  productType: "lightRed",
}

const ICONS_FOR_COLOR_SCHEMES: Record<ColorScheme, string> = {
  lightBlue: shieldBlueIcon,
  lightYellow: shieldYellowIcon,
  lightRed: shieldRedIcon,
}

const TRANSPARENT_ICONS_FOR_COLOR_SCHEMES: Record<ColorScheme, string> = {
  lightBlue: shieldTransparentBlueIcon,
  lightYellow: shieldTransparentYellowIcon,
  lightRed: shieldTransparentRedIcon,
}
