import {FC, RefObject} from "react"

import {Button} from "@frontend/components/ui"
import {useTranslation} from "@frontend/i18n"
import {DiyStep} from "@ri2/db/client"
import {css} from "@styled-system/css"

import {getStepItemTitle} from "../util/get-step-title"

interface Props {
  step: DiyStep
  index: number
  idToScrollTo: string
  containerRef: RefObject<HTMLDivElement>
}

export const StepItem: FC<Props> = ({
  step,
  index,
  idToScrollTo,
  containerRef,
}) => {
  const onClick = (): void => {
    const element = containerRef.current!.querySelector(`#${idToScrollTo}`)
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest",
      })
    } else {
      console.error(`Element with id ${idToScrollTo} not found`)
    }
  }
  const t = useTranslation()

  return (
    <Button
      type="unstyled"
      onClick={onClick}
      className={css({
        width: "100%",
        minWidth: 0,
        borderRadius: 8,
        paddingY: 6,
        paddingLeft: 12,
        paddingRight: 8,
        maxHeight: 32,
        minHeight: 32,
        textAlign: "left",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "fontGrey",
        _hover: {
          backgroundColor: "lightGrey",
          color: "fontBlack",
        },
      })}
    >
      {getStepItemTitle(step, index + 1, t)}
    </Button>
  )
}
