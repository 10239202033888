import {useLocation} from "react-router-dom"

import {
  DIY_DOCUMENTS_SEGMENT,
  DIY_STEPS,
  DIY_VIDEOS_SEGMENT,
} from "@frontend/routing"
import {FeedbackOnMergedRemediationHashType} from "@ri2/db/client"

export const TABS = [
  DIY_STEPS,
  DIY_VIDEOS_SEGMENT,
  DIY_DOCUMENTS_SEGMENT,
] as const

export type Tab = (typeof TABS)[number]

export const useCurrentTab = (): Tab => {
  const {pathname} = useLocation()

  if (/steps$/.test(pathname)) {
    return "steps"
  }
  if (/videos$/.test(pathname)) {
    return "videos"
  }
  if (/documents$/.test(pathname)) {
    return "documents"
  }

  return "steps"
}

export const TABS_TO_DIY_FEEDBACK_TYPE: Record<
  Tab,
  FeedbackOnMergedRemediationHashType
> = {
  steps: "STEPS",
  videos: "VIDEOS",
  documents: "DOCUMENTS",
}
