import type {Case as BaseCase, ResolveEvent} from "@prisma/client"

import {Message} from "../message"

export type {BaseCase}

export interface CaseSummary {
  id: string
  name?: string
  resolved: boolean
  date: Date
}

export type Case = BaseCase & {
  messages: Message[]
  resolveEvents: ResolveEvent[]
}

export const getCaseResolved = ({
  resolveEvents,
}: Pick<Case, "resolveEvents">): boolean =>
  !!resolveEvents[resolveEvents.length - 1]?.resolved

export type CaseEvent =
  | ({eventType: "message"} & Message)
  | ({eventType: "resolveEvent"} & ResolveEvent)

export const getCaseEvents = ({
  messages,
  resolveEvents,
}: Pick<Case, "messages" | "resolveEvents">): CaseEvent[] => {
  const messagesWithType = messages.map(
    (message): CaseEvent => ({
      eventType: "message",
      ...message,
    }),
  )
  const resolveEventsWithType = resolveEvents.map(
    (resolveEvent): CaseEvent => ({
      eventType: "resolveEvent",
      ...resolveEvent,
    }),
  )

  return [...messagesWithType, ...resolveEventsWithType].sort(
    (event1, event2) => {
      const time1 = event1.createdAt.getTime()
      const time2 = event2.createdAt.getTime()

      // resolve events should be sorted before messages if the times
      // are equal, because a message is created simultaneously with a
      // resolve event when a case is unresolved
      if (time1 === time2) {
        return event1.eventType === "resolveEvent" ? -1 : 1
      }

      return time1 - time2
    },
  )
}
