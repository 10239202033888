import {FC} from "react"
import {Link} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {iconsColor} from "@frontend/design"
import chevronWhite from "@frontend/design/icons/chevron-white.svg"
import {makePath} from "@frontend/routing"
import {CauseOnMessageWithCauseAndPart} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {CauseIcon} from "./cause-icon"

export type CauseVariant = "darkBlue" | "lightBlue"

const styles = {
  darkBlue: {
    descriptionColor: "lightSkyBlue",
    titleColor: "#FFFFFF",
    borderColor: "#394D84",
    chevronColor: "white" as const,
    paddingX: 4,
    iconBackground: "rgba(255, 255, 255, 0.2)",
  },
  lightBlue: {
    descriptionColor: "darkBlue",
    titleColor: "darkBlue",
    borderColor: "#394D8433",
    chevronColor: "royalBlue" as const,
    paddingX: 12,
    iconBackground: "rgba(255, 73, 2, 1)",
  },
}

interface Props {
  caseId: string
  cause: CauseOnMessageWithCauseAndPart
  variant?: CauseVariant
  className?: string
}

export const Cause: FC<Props> = ({
  caseId,
  cause,
  className,
  variant = "darkBlue",
}) => {
  const path = makePath({
    name: "cases",
    caseId,
    causeOnMessageId: cause.id,
  })

  return (
    <Link
      className={cx(
        hstack({
          width: "100%",
          overflow: "hidden",
          paddingY: 24,
          paddingX: styles[variant].paddingX,
          gap: 12,
          borderTopWidth: 1,
          position: "relative",
          backgroundColor: "transparent",
          alignItems: "center",
        }),
        className,
      )}
      style={{
        borderTopColor: styles[variant].borderColor,
      }}
      to={path}
      replace
    >
      <CauseIcon
        color={styles[variant].iconBackground}
        className={css({flexShrink: 0, flexGrow: 0})}
      />
      <div
        className={vstack({
          gap: 2,
          alignItems: "flex-start",
          flexGrow: 1,
          width: "auto",
        })}
      >
        <h3
          className={css({color: styles[variant].titleColor, fontWeight: 700})}
        >
          {cause.cause.name}
        </h3>
        <p
          className={css({
            color: styles[variant].descriptionColor,
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: 13,
            fontWeight: 400,
            lineHeight: "16px",
            letterSpacing: "-0.02em",
            lineClamp: 2,
          })}
        >
          {cause.cause.description}
        </p>
      </div>
      <div
        className={hstack({
          minWidth: 32,
          justifyContent: "center",
          alignItems: "center",
          paddingX: 4,
          paddingY: 16,
        })}
      >
        <ReactSVG
          src={chevronWhite}
          className={cx(
            css({
              transform: "rotate(180deg)",
              "& svg": {
                width: 24,
                height: 24,
              },
            }),
            iconsColor({
              color: styles[variant].chevronColor ?? "white",
            }),
          )}
        />
      </div>
    </Link>
  )
}
