import type {Part as PartModel} from "@ri2/rc-db"
import pluralize from "pluralize"
import {FC} from "react"
import {useNavigate} from "react-router-dom"

import {Button, ReactionsWithForm, Tag} from "@frontend/components"
import {InformationBanner} from "@frontend/components/ui/information-banner"
import modelNumber from "@frontend/design/icons/model-number.svg"
import shieldIcon from "@frontend/design/icons/shield-transparent-blue.svg"
import stopSignIcon from "@frontend/design/icons/stop-sign.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {
  FeedbackOnMergedRemediationHashType,
  MergedRemediationWithHashIdAndFeedback,
} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {DataQualityTag} from "./components/data-quality-tag"
import {Part} from "./components/part"
import {useDataFromParams} from "./params"

export const Parts: FC<{
  remediation: Pick<
    MergedRemediationWithHashIdAndFeedback,
    "hashId" | "feedback"
  >
  feedbackType: FeedbackOnMergedRemediationHashType
}> = ({remediation, feedbackType}) => {
  const {
    remediation: {cause, partsByPartType},
  } = useDataFromParams()

  const t = useTranslation()

  return (
    <div
      className={vstack({
        alignItems: "stretch",
        gap: 16,
        position: "relative",
        justifyContent: "space-between",
      })}
    >
      <div
        className={vstack({
          alignItems: "stretch",
          flexShrink: 1,
          flexGrow: 1,
          overflow: "auto",
          gap: 0,
        })}
      >
        <div
          className={hstack({
            gap: 4,
            paddingBottom: 12,
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <span
            className={css({
              opacity: 0.8,
              color: "white",
              fontWeight: 700,
              fontSize: 13,
              lineHeight: "24px",
            })}
          >
            {t("cause.diy.parts.title")}
          </span>
          {partsByPartType.source.type === "match" &&
            cause.remediationRequiresParts && (
              <DataQualityTag
                source={partsByPartType.source}
                mode="part"
                transparent
              />
            )}
        </div>
        <div
          className={vstack({
            borderTopColor: "rgba(255,255,255,0.3)",
            borderTopWidth: 1,
            alignItems: "stretch",
          })}
        >
          <Content />
        </div>
      </div>
      <ReactionsWithForm
        on={{
          on: "remediation",
          mergedRemediationHashId: remediation.hashId,
          type: feedbackType,
        }}
        currentFeedback={remediation.feedback[feedbackType]}
        className={css({
          alignSelf: "flex-end",
          flexShrink: 0,
          flexGrow: 0,
        })}
        transparent
      />
    </div>
  )
}

const Content: FC = () => {
  const {
    remediation: {cause, partsByPartType},
    enrichedProductInfo,
  } = useDataFromParams()

  const parts = partsByPartType.partTypes.flatMap(({parts}) => parts)

  if (!cause.remediationRequiresParts) {
    return <NoPartsRequired />
  }

  if (!enrichedProductInfo.modelNumber) {
    return <ModelNumberPrompt />
  }

  if (partsByPartType.source.type !== "match" || parts.length === 0) {
    return <ModelNotInDatabasePrompt />
  }

  return <PartsList parts={parts} />
}

interface PartsListProps {
  parts: PartModel[]
}

const PartsList: FC<PartsListProps> = ({parts}) => {
  const partType = parts[0].partType?.name
  const t = useTranslation()

  return (
    <div
      className={vstack({
        flex: 1,
        gap: {
          base: 16,
          desktop: 0,
        },
        flexWrap: "nowrap",
        overflowX: "auto",
        alignItems: "stretch",
        justifyContent: "center",
        paddingBottom: 0,
      })}
    >
      {parts.length > 1 && partType && (
        <div
          className={vstack({
            gap: 0,
            alignItems: "flex-start",
            paddingTop: 16,
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "20px",
            color: "white",
          })}
        >
          <div>{pluralize(partType)}</div>
          <div
            className={css({
              color: "rgba(255, 255, 255, 0.5)",
              fontSize: 13,
              fontWeight: 400,
            })}
          >
            {t("cause.diy.parts.multipleAvailable")}
          </div>
        </div>
      )}
      {parts.map((part, index) => (
        <Part
          part={part}
          mode={parts.length > 1 ? "list" : "single"}
          className={css({
            flexShrink: 0,
            borderBottomColor: "rgba(255, 255, 255, 0.1)",
            borderBottomWidth: index === parts.length - 1 ? 0 : 1,
          })}
          key={index}
        />
      ))}
      {parts.length > 1 && (
        <InformationBanner
          variant="blue"
          transparent
          content={t("cause.diy.parts.multiple")}
        />
      )}
    </div>
  )
}

const NoPartsRequired: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingY: 20,
        gap: 8,
        alignItems: "stretch",
      })}
    >
      <Tag
        title={t("cause.diy.empty.noPartsRequiredTag")}
        icon={shieldIcon}
        colorScheme="lightBlue"
        transparent
        className={css({
          alignSelf: "flex-start",
        })}
      />
      <p
        className={css({
          textStyle: "new16",
          color: "rgba(255, 255, 255, 0.5)",
        })}
      >
        {t("cause.diy.empty.noPartsRequired")}
      </p>
    </div>
  )
}

const ModelNumberPrompt: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingTop: 16,
        paddingBottom: 24,
        gap: 8,
        alignItems: "flex-start",
      })}
    >
      <img src={modelNumber} alt="" />
      <p
        className={css({
          textStyle: "new16",
          color: "white",
        })}
      >
        {t("cause.diy.empty.noModelNumber")}
      </p>
      <AddModelNumberButton />
    </div>
  )
}

const ModelNotInDatabasePrompt: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingTop: 16,
        paddingBottom: 24,
        gap: 8,
        alignItems: "flex-start",
      })}
    >
      <img src={stopSignIcon} alt="" />
      <p
        className={css({
          textStyle: "new16",
          color: "rgba(255, 255, 255, 0.5)",
        })}
      >
        {t("cause.diy.empty.modelNotInDatabase")}
      </p>
      <AddModelNumberButton />
    </div>
  )
}

const AddModelNumberButton: FC = () => {
  const navigate = useNavigate()
  const {caseId} = useDataFromParams()

  const onAddModelNumber = (): void => {
    navigate(makePath({name: "cases", caseId}))
  }

  const t = useTranslation()

  return (
    <Button
      type="unstyled"
      onClick={onAddModelNumber}
      className={hstack({
        flexGrow: 1,
        flexShrink: 1,
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "rgba(53, 94, 190, 1)",
        height: 32,
        paddingX: 10,
        borderRadius: 8,
        boxShadow:
          "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset",
        color: "white",
        fontSize: 14,
      })}
    >
      {t("cause.diy.empty.addModelNumber")}
    </Button>
  )
}
