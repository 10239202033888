import {FC, useState} from "react"

import {Button} from "@frontend/components/ui"
import playIcon from "@frontend/design/icons/play-icon.svg"
import {getImageUrl} from "@frontend/utils/getImageUrl"
import {DiyVideoWithSource, MergedRemediation} from "@ri2/db/client"
import {css} from "@styled-system/css"

import {VideoModal} from "./video-modal"
import {DataQualityTag} from "../../components/data-quality-tag"
import {formatDurationInSeconds} from "../util/formatDurationInSeconds"

interface Props {
  video: DiyVideoWithSource
  diyRemediation: NonNullable<MergedRemediation["diyRemediation"]>
}

export const Video: FC<Props> = ({video, diyRemediation}) => (
  <>
    <DesktopVideo video={video} diyRemediation={diyRemediation} />
    <MobileVideo video={video} />
  </>
)

const DesktopVideo: FC<Props> = ({
  video,
  video: {name, thumbnailUrl, source},
  diyRemediation,
}) => {
  // TODO
  const durationSeconds = 60

  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <Button
        type="unstyled"
        onClick={(): void => {
          setShowModal(true)
        }}
        external
        className={css({
          display: {base: "none", desktop: "flex"},
          flexDirection: "column",
          gridColumn: "span 3",
        })}
      >
        <div
          className={css({
            borderRadius: 12,
            aspectRatio: "17 / 10",
            width: "100%",
            objectFit: "cover",
            overflow: "hidden",
            position: "relative",
          })}
        >
          <img
            src={getImageUrl(thumbnailUrl)}
            alt=""
            className={css({
              width: "100%",
              height: "100%",
              objectFit: "cover",
            })}
          />
          <div
            className={css({
              position: "absolute",
              left: "calc(50% - 48px/2)",
              top: "calc(50% - 48px/2)",
              width: 48,
              height: 48,
              borderRadius: 24,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.15)",
              backdropFilter: "blur(25px)",
            })}
          >
            <img src={playIcon} alt="" />
          </div>
          <div
            className={css({
              position: "absolute",
              right: 12,
              bottom: 12,
              borderRadius: 8,
              backgroundColor: "rgba(0, 0, 0, 0.15)",
              backdropFilter: "blur(25px)",
              paddingX: 12,
              paddingY: 4,
              textStyle: "body2",
              color: "white",
            })}
          >
            {formatDurationInSeconds(durationSeconds)}
          </div>
        </div>
        <div
          className={css({
            display: "flex",
            flexDirection: "column",
            gap: 6,
            paddingY: 16,
            textAlign: "start",
          })}
        >
          <p
            className={css({
              textStyle: "body",
              color: "fontBlack",
            })}
          >
            {name}
          </p>
          <DataQualityTag source={source} />
        </div>
      </Button>
      <VideoModal
        video={video}
        diyRemediation={diyRemediation}
        show={showModal}
        onDismiss={(): void => {
          setShowModal(false)
        }}
      />
    </>
  )
}

const MobileVideo: FC<Omit<Props, "diyRemediation">> = ({
  video: {name, url, source},
}) => (
  <div
    className={css({
      display: {base: "flex", desktop: "none"},
      flexDirection: "column",
    })}
  >
    <iframe
      src={url}
      allowFullScreen
      className={css({
        aspectRatio: "16 / 9",
        width: "100%",
      })}
    />
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        gap: 4,
        paddingY: 16,
        textAlign: "start",
      })}
    >
      <p
        className={css({
          textStyle: "body",
          color: "fontBlack",
        })}
      >
        {name}
      </p>
      <DataQualityTag source={source} />
    </div>
  </div>
)
