import {FC} from "react"

import {useNavigateUpBreadcrumbs} from "@frontend/app-container/context"
import {Button, ReactionsWithForm} from "@frontend/components"
import chevron from "@frontend/design/icons/chevron.svg"
import {useTranslation} from "@frontend/i18n"
import {
  FeedbackOnMergedRemediationHashType,
  MergedRemediationWithHashIdAndFeedback,
} from "@ri2/db/client"
import {hstack} from "@styled-system/patterns"

type Props =
  | {
      remediation: undefined
      feedbackType: undefined
    }
  | {
      remediation: Pick<
        MergedRemediationWithHashIdAndFeedback,
        "hashId" | "feedback"
      >
      feedbackType?: FeedbackOnMergedRemediationHashType
    }

export const MobileBackHeader: FC<Props> = ({remediation, feedbackType}) => {
  const onBack = useNavigateUpBreadcrumbs()
  const t = useTranslation()

  return (
    <div
      className={hstack({
        justifyContent: "space-between",
        marginTop: 24,
        marginBottom: 28,
        paddingX: 16,
        display: {
          base: "flex",
          desktop: "none",
        },
      })}
    >
      <Button
        type="unstyled"
        onClick={onBack}
        className={hstack({gap: 4, textStyle: "button", color: "fontBlack"})}
      >
        <img src={chevron} alt="" />
        {t("general.back")}
      </Button>
      {remediation && feedbackType && (
        <ReactionsWithForm
          on={{
            on: "remediation",
            mergedRemediationHashId: remediation.hashId,
            type: feedbackType,
          }}
          currentFeedback={remediation.feedback[feedbackType]}
        />
      )}
    </div>
  )
}
