import {FC, useEffect, useState} from "react"

import {FeedbackContainer} from "@frontend/components/feedback"
import {OnMessage, OnRemediation} from "@frontend/components/feedback/types"
import {useTranslation} from "@frontend/i18n"
import {useSession} from "@frontend/session"
import {useCreateFeedbackMutation} from "@frontend/utils/trpc"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {Feedback as FeedbackModel, FeedbackRating} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Reaction} from "./reaction"

interface ReactionsProps {
  rating?: FeedbackRating
  onRate: (rating: FeedbackRating) => void
  className?: string
  tootltipPlacement?: "top" | "bottom"
  tooltipVariant?: "light" | "dark"
  isSaved?: boolean
  transparent?: boolean
}

export const Reactions: FC<ReactionsProps> = ({
  rating,
  onRate,
  className,
  tootltipPlacement,
  tooltipVariant,
  isSaved = false,
  transparent = false,
}) => {
  const t = useTranslation()
  return (
    <div className={cx(hstack({gap: 16, alignItems: "center"}), className)}>
      <div
        className={hstack({
          padding: 4,
          gap: 8,
          position: "relative",
        })}
      >
        <Reaction
          rating="POSITIVE"
          selected={rating === "POSITIVE"}
          tooltip={t("feedback.general.POSITIVE.tooltip")}
          tooltipPlacement={tootltipPlacement}
          tooltipVariant={tooltipVariant}
          transparent={transparent}
          onClick={() => {
            onRate("POSITIVE")
          }}
        />
        <Reaction
          rating="NEGATIVE"
          selected={rating === "NEGATIVE"}
          tooltip={t("feedback.general.NEGATIVE.tooltip")}
          tooltipPlacement={tootltipPlacement}
          tooltipVariant={tooltipVariant}
          transparent={transparent}
          onClick={() => {
            onRate("NEGATIVE")
          }}
        />
      </div>
      {isSaved && (
        <span
          className={css({
            textStyle: "caption",
            paddingX: 12,
            paddingY: 10,
            color: "fontGrey",
            backgroundColor: "lightGrey",
            borderRadius: 10,
            minWidth: 64,
            height: 32,
          })}
        >
          {t("feedback.thanks")}
        </span>
      )}
    </div>
  )
}

interface ReactionsWithFormProps {
  on: Omit<OnRemediation, "feedback"> | Omit<OnMessage, "feedback">
  currentFeedback?: FeedbackModel
  className?: string
  tooltipPlacement?: "top" | "bottom"
  tooltipVariant?: "light" | "dark"
  transparent?: boolean
  isInline?: boolean
}

export const ReactionsWithForm: FC<ReactionsWithFormProps> = ({
  on,
  currentFeedback,
  className,
  tooltipPlacement,
  tooltipVariant,
  isInline = false,
  transparent,
}) => {
  const {
    state: isSaved,
    setTrue: setSaved,
    setFalse: setNotSaved,
  } = useBooleanState(false)
  const [feedback, setFeedback] = useState<FeedbackModel | undefined>(undefined)
  const [previousFeedback, setPreviousFeedback] = useState<
    FeedbackModel | undefined
  >(undefined)

  const onClose = (): void => {
    if (isInline) setSaved()
    setFeedback(undefined)
  }

  const createFeedbackMutation = useCreateFeedbackMutation((feedback): void => {
    setFeedback(feedback)
  })

  const {userRcId} = useSession()

  const onRate = (rating: FeedbackRating): void => {
    if (currentFeedback?.rating === rating) {
      setPreviousFeedback(currentFeedback)
    }

    createFeedbackMutation.mutate({
      userRcId,
      rating,
      on,
    })
  }

  useEffect(() => {
    if (isSaved) {
      setTimeout(() => {
        setNotSaved()
      }, 2000)
    }
  }, [isSaved, setNotSaved])

  return (
    <>
      <Reactions
        rating={currentFeedback?.rating}
        onRate={onRate}
        className={className}
        tootltipPlacement={tooltipPlacement}
        tooltipVariant={tooltipVariant}
        isSaved={isSaved}
        transparent={transparent}
      />
      {feedback && (
        <FeedbackContainer
          on={{...on, feedback}}
          previousFeedback={previousFeedback}
          onClose={onClose}
        />
      )}
    </>
  )
}
