import {FC} from "react"

import {DesktopAndLargeDesktop} from "@frontend/components/responsive"
import {Button} from "@frontend/components/ui"
import missingPartImage from "@frontend/design/icons/missing-part-image.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {CauseOnMessageWithCauseAndPart} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

interface Props {
  caseId: string
  cause: CauseOnMessageWithCauseAndPart
  className?: string
}

export const Cause: FC<Props> = ({caseId, cause, className}) => {
  const path = makePath({
    name: "cases",
    caseId,
    causeOnMessageId: cause.id,
  })

  const t = useTranslation()

  return (
    <div
      className={cx(
        css({
          width: "100%",
          overflow: "hidden",
          padding: 16,
          borderRadius: 12,
          borderWidth: 1,
          borderColor: "lineGrey",
          position: "relative",
          backgroundColor: "white",
        }),
        className,
      )}
    >
      <div
        className={hstack({
          gap: 24,
          alignItems: "stretch",
        })}
      >
        <div
          className={vstack({
            gap: 8,
            width: "100%",
            alignItems: "start",
          })}
        >
          <div
            className={css({
              lineClamp: 1,
              fontSize: 14,
              fontWeight: 700,
              lineHeight: "20px",
              color: "fontBlack",
            })}
          >
            {cause.cause.name}
          </div>
          <div
            className={css({
              lineClamp: 3,
              textAlign: "left",
              textStyle: "body2",
              color: "fontGrey",
            })}
          >
            {cause.cause.description}
          </div>
          <DesktopAndLargeDesktop>
            <Button
              state="primary"
              type="large"
              title={t("cases.case.nextStepsAction")}
              navigateTo={path}
              className={css({
                width: "100%",
              })}
            />
          </DesktopAndLargeDesktop>
        </div>
        <div
          className={vstack({
            justifyContent: "space-between",
            width: 110,
            flexShrink: 0,
          })}
        >
          <img
            src={cause.part?.image?.url ?? missingPartImage}
            alt=""
            className={css({
              width: "100%",
              aspectRatio: 1,
              borderRadius: 4,
              objectFit: "cover",
            })}
          />
        </div>
      </div>
      <Button
        state="primary"
        type="large"
        title={t("cases.case.nextStepsAction")}
        navigateTo={path}
        className={css({
          width: "100%",
          marginTop: 14,
          display: {
            base: "block",
            desktop: "none",
          },
        })}
      />
    </div>
  )
}
