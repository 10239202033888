import {lowerCase} from "lodash"
import {FC} from "react"

import {Tag} from "@frontend/components"
import {Button} from "@frontend/components/ui"
import pdfFile from "@frontend/design/icons/pdf-file.svg"
import {getImageUrl} from "@frontend/utils/getImageUrl"
import type {ProductDocument as DocumentModel} from "@ri2/db/client"
import {css} from "@styled-system/css"

interface Props {
  document: DocumentModel
}

export const Document: FC<Props> = ({document: {category, name, url}}) => (
  <Button
    type="unstyled"
    navigateTo={getImageUrl(url)}
    external
    className={css({
      display: "flex",
      flexDirection: "column",
      gap: 8,
      desktop: {
        gridColumn: "span 3",
      },
      minHeight: {
        base: 193,
        desktop: "auto",
      },
    })}
  >
    <div
      className={css({
        width: "100%",
        position: "relative",
        flexGrow: 1,
        backgroundColor: "lightGrey",
        borderRadius: 12,
        borderStyle: "solid",
        borderColor: "lineGrey",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: {
          base: 193,
          desktop: 148,
        },
        maxHeight: {
          base: 193,
          desktop: 148,
        },
      })}
    >
      <div className={css({position: "absolute", top: 10, left: 10})}>
        <Tag
          title={lowerCase(category)}
          className={css({height: 24, textTransform: "capitalize"})}
        />
      </div>
      <img src={pdfFile} alt="" />
    </div>
    <span
      className={css({
        textStyle: "body2",
      })}
    >
      {name}
    </span>
  </Button>
)
