import {FC} from "react"

import {CopyLinkButton} from "@frontend/components"
import {hstack} from "@styled-system/patterns"

import {DownloadButton} from "./download-button"

export const MobileFooter: FC<{mediaUrl: string; isImage: boolean}> = ({
  mediaUrl,
  isImage,
}) => (
  <div
    className={hstack({
      gap: 8,
      justifyContent: "center",
      padding: 16,
      desktop: {
        display: "none",
      },
    })}
  >
    {isImage && <DownloadButton imageUrl={mediaUrl} />}
    <CopyLinkButton url={mediaUrl} theme="dark" state="secondary" />
  </div>
)
