import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

import {Video} from "./components/video"
import {Empty} from "../components/empty"
import {useDataFromParams} from "../params"

const VideosPage: FC = () => {
  const {
    remediation: {
      diyRemediation,
      diyRemediation: {videos},
    },
  } = useDataFromParams()

  const t = useTranslation()

  if (videos.length === 0) {
    return (
      <Empty
        message={t("cause.diy.empty.videos")}
        className={css({
          alignSelf: "center",
          height: "100%",
        })}
      />
    )
  }
  return (
    <div
      className={css({
        display: "grid",
        marginTop: 30,
        gridTemplateColumns: {
          base: "repeat(1, 1fr)",
          desktop: "repeat(6, 1fr)",
        },
        maxHeight: "95%",
        overflowY: "auto",
        gap: 24,
      })}
    >
      {videos.map((video, index) => (
        <Video video={video} diyRemediation={diyRemediation} key={index} />
      ))}
    </div>
  )
}

export default VideosPage
