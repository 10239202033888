import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import type {UiCase} from "@ri2/app/server/routers/getCasePageData"
import {css} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

interface Props {
  caseModel: UiCase
}

export const CaseHeader: FC<Props> = ({
  caseModel: {name, modelDescription, modelNumber},
}) => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        alignItems: {
          base: "center",
          desktop: "flex-start",
        },
        gap: 2,
      })}
    >
      <div
        className={css({
          fontSize: 18,
          fontWeight: 700,
          lineHeight: "24px",
          color: "rgba(51, 51, 51, 1)",
          lineClamp: 1,
          textAlign: "center",

          desktop: {
            fontSize: 24,
            lineHeight: "29.05px",
            letterSpacing: "-1%",
            textAlign: "left",
          },
        })}
      >
        {name}
      </div>
      <div
        className={css({
          fontSize: 13,
          fontWeight: 400,
          lineHeight: "16px",
          lineClamp: 1,
          textAlign: "center",

          desktop: {
            textAlign: "left",
          },
        })}
      >
        <span
          className={css({
            color: "rgba(117, 117, 117, 1)",
          })}
        >
          {modelDescription} {"/"}
        </span>{" "}
        <span
          className={css({
            color: "rgba(94, 89, 89, 1)",
          })}
        >
          {modelNumber || t("appContainer.header.noModelNumber")}
        </span>
      </div>
    </div>
  )
}
