import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components/ui/button"
import {iconsColor} from "@frontend/design"
import check from "@frontend/design/icons/check.svg"
import linkIcon from "@frontend/design/icons/link.svg"
import {useTranslation} from "@frontend/i18n"
import {useCopyText} from "@frontend/utils/useCopyText"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

export const CopyLink: FC = () => {
  const t = useTranslation()

  const url = window.location.href
  const [isCopied, onClickToCopy] = useCopyText(url)

  return (
    <div
      className={css({
        paddingX: 8,
        width: "100%",
        marginBottom: 4,
      })}
    >
      <div
        className={hstack({
          paddingBottom: 12,
          borderBottomWidth: 1,
          justifyContent: "space-between",
          borderColor: "#C4D4FF",
        })}
      >
        <p
          className={css({
            color: "royalBlue",
          })}
        >
          {t("conversation.caseLink.message")}
        </p>
        <Button
          type="unstyled"
          onClick={onClickToCopy}
          className={hstack({
            color: "royalBlue",
            backgroundColor: "#F3F6FC",
            paddingY: 8,
            paddingX: 10,
            borderRadius: 8,
            justifyContent: "space-between",
            gap: 4,
            border: "1px solid #98B9E7",
            textTransform: "uppercase",
            fontWeight: "600",
            fontSize: 13,
            lineHeight: "20px",
            boxShadow:
              "0px 1px 2px rgba(16, 24, 40, 0.05), inset 0px 0px 0px 1px rgba(16, 24, 40, 0.18), inset 0px -2px 0px rgba(16, 24, 40, 0.05)",
          })}
        >
          <ReactSVG
            src={isCopied ? check : linkIcon}
            className={isCopied ? iconsColor({color: "darkBlue"}) : ""}
          />
          {isCopied ? t("case.copied") : t("case.copyLink")}
        </Button>
      </div>
    </div>
  )
}
