const MESSAGE_TERMINATOR = "\u2300"
const MESSAGE_STATUS_DELINEATOR = "\u2301"
const MESSAGE_BODY_DELINEATOR = "\u2302"

// Parses a stream response string that is expected to consist of status chunks
// followed by message chunks
// ⌁Analyzing Causes⌁⌁Deciding next steps⌁⌂Thank you for this. Goodbye
// Status Chunk      Status Chunk         Message Chunk        Message Chunk

interface Parsed {
  pendingStatus: string | null
  msg: string
  isTerminated: boolean
}

export const parseStream = (stream: string): Parsed => {
  const isTerminated = stream.includes(MESSAGE_TERMINATOR)
  const cleaned = stream.replace(MESSAGE_TERMINATOR, "")

  const splitStream = cleaned.split(MESSAGE_BODY_DELINEATOR)
  if (splitStream.length > 1) {
    return {pendingStatus: null, msg: splitStream[1], isTerminated}
  }

  // The message has not started streaming so just give the last complete status
  //
  // ⌁Analyzing Causes⌁⌁Deciding next step⌁ -> Deciding next step
  // ⌁Analyzing Causes⌁⌁Deciding            -> Analyzing Causes

  const statusChunks = cleaned
    .split(MESSAGE_STATUS_DELINEATOR)
    .filter((s) => !!s)

  statusChunks.reverse()
  const lastChar = cleaned[cleaned.length - 1]

  if (lastChar === MESSAGE_STATUS_DELINEATOR) {
    // The last chunk is a complete status
    return {pendingStatus: statusChunks[0] || null, msg: "", isTerminated}
  } else {
    // The last chunk is an incomplete status so use second to last status
    return {pendingStatus: statusChunks[1] || null, msg: "", isTerminated}
  }
}
