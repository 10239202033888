import {FC} from "react"

import {Button} from "@frontend/components"
import {cva} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

const toggleButtonStyle = cva({
  base: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    padding: "3px 8px 3px 8px",
    borderRadius: 8,
    fontWeight: "600",
    fontSize: 13,
  },
  variants: {
    selected: {
      true: {
        backgroundColor: "white",
        color: "#1740BB",
        border: "1px solid #C4D4FF",
        "& span": {
          backgroundColor: "#2F57BC33",
        },
      },
      false: {
        color: "white",
        "& span": {
          backgroundColor: "#FFFFFF33",
        },
      },
    },
  },
})

export const ToggleButton: FC<{
  selected: boolean
  title: string
  onSelected: () => void
  count: number
}> = ({selected, title, onSelected, count}) => (
  <Button
    type="unstyled"
    className={toggleButtonStyle({
      selected,
    })}
    onClick={onSelected}
  >
    {title}
    <span
      className={hstack({
        borderRadius: 4,
        height: 20,
        paddingX: 2,
        fontSize: 13,
        fontWeight: "600",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      {count}
    </span>
  </Button>
)
