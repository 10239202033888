import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button, CopyLinkButton} from "@frontend/components"
import {iconsColor} from "@frontend/design"
import breadcrumbChevronIcon from "@frontend/design/icons/breadcrumb-chevron.svg"
import close from "@frontend/design/icons/close.svg"
import image from "@frontend/design/icons/image.svg"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {DownloadButton} from "./download-button"

export const Header: FC<{
  title: string
  onDismiss: () => void
  mediaUrl: string
  isImage: boolean
}> = ({onDismiss, title, mediaUrl, isImage}) => (
  <div
    className={hstack({
      paddingX: {
        base: 16,
        desktop: 20,
      },
      paddingY: 16,
      justifyContent: "space-between",
    })}
  >
    <span
      className={css({
        display: {
          base: "block",
          desktop: "none",
        },
        width: 16,
      })}
    />
    <div
      className={hstack({
        gap: 20,
        justifyContent: {
          base: "center",
          desktop: "unset",
        },
      })}
    >
      <div
        className={css({
          padding: 6,
          background: "rgba(255, 255, 255, 0.15)",
          minWidth: "fit-content",
          borderRadius: 12,
          width: 36,
          height: 36,
          display: {base: "none", desktop: "flex"},
          alignItems: "center",
          justifyContent: "center",
        })}
      >
        <img
          src={image}
          alt="logo"
          className={css({
            width: 16,
            height: 16,
          })}
        />
      </div>
      <div className={hstack({gap: 16})}>
        <ReactSVG
          src={breadcrumbChevronIcon}
          className={cx(
            iconsColor({color: "white"}),
            css({display: {base: "none", desktop: "block"}}),
          )}
        />
        <span
          className={css({
            color: "white",
            fontSize: 18,
            fontWeight: 700,
            lineHeight: "24px",
            marginX: {
              base: "auto",
              desktop: "unset",
            },
          })}
        >
          {title}
        </span>
      </div>
    </div>
    <div className={hstack({gap: 20})}>
      <div className={css({display: {base: "none", desktop: "contents"}})}>
        {isImage && <DownloadButton imageUrl={mediaUrl} />}

        <div
          className={css({
            width: 120,
          })}
        >
          <CopyLinkButton url={mediaUrl} theme="dark" state="secondary" />
        </div>
      </div>
      <Button
        type="icon"
        background="none"
        icon={<ReactSVG src={close} className={iconsColor({color: "white"})} />}
        alt="close"
        onClick={onDismiss}
      />
    </div>
  </div>
)
