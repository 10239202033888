import {FC} from "react"
import {Trans} from "react-i18next"

import {Button, Modal} from "@frontend/components"
import info from "@frontend/design/icons/info.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

interface Props {
  show: boolean
  onClose: () => void
}

export const ConsentModal: FC<Props> = ({show, onClose}) => {
  const t = useTranslation()

  return (
    <Modal
      title={t("cases.consentModal.title")}
      message={
        <Trans t={t} i18nKey="cases.consentModal.description">
          <a
            href="https://www.repairclinic.com/Privacy-Policy"
            target="_blank"
            className={css({color: "lightBlue", cursor: "pointer"})}
            rel="noreferrer"
          />
        </Trans>
      }
      show={show}
      onDismiss={onClose}
      width={610}
      icon={
        <div
          className={css({
            borderRadius: 8,
            backgroundColor: "#E7F0FB",
            padding: 6,
            minWidth: 36,
            minHeight: 36,
            maxHeight: 36,
          })}
        >
          <img
            src={info}
            alt=""
            className={css({minWidth: 24, minHeight: 24})}
          />
        </div>
      }
    >
      <div className={hstack()}>
        <Button
          type="large"
          state="primary"
          onClick={onClose}
          className={css({
            width: 120,
            height: {
              base: 48,
              desktop: 40,
            },
          })}
          title={t("cases.consentModal.action")}
        />
      </div>
    </Modal>
  )
}
