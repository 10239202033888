import {motion} from "framer-motion"
import {FC, useEffect, useRef, useState} from "react"

import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {useMobileCaseLayoutContext} from "./mobile-case-layout/context"
import {Badge, SelectableButton} from "./selectable-button"

interface ChatCausesSwitchProps {
  className?: string
}

export const ChatCausesSwitch: FC<ChatCausesSwitchProps> = ({className}) => {
  const {causesCount, showCauses, tab, onChangeTab} =
    useMobileCaseLayoutContext()
  const containerRef = useRef<HTMLDivElement>(null)

  const [switchTop, setSwitchTop] = useState(48)

  useEffect(() => {
    if (showCauses) {
      setSwitchTop(0)
    } else {
      setSwitchTop(48)
    }
  }, [showCauses])

  const t = useTranslation()

  return (
    <motion.div
      ref={containerRef}
      initial={false}
      variants={{
        atTheBottom: {
          marginBottom: 0,
          y: `${switchTop}px`,
          transition: {duration: 0.5, ease: "easeOut"},
        },
        atTheTop: {
          marginBottom: -48,
          y: `${switchTop}px`,
          transition: {duration: 0.01},
        },
      }}
      animate={switchTop === 0 ? "atTheBottom" : "atTheTop"}
      className={vstack({
        alignSelf: "center",
        zIndex: 1,
        bottom: 0,
        backgroundColor: tab === "conversation" ? "bgBlue" : "darkBlue",
        width: "100%",
        minHeight: 48,
        display: {base: "flex", desktop: "none"},
        justifyContent: "center",
      })}
    >
      <motion.div
        animate={showCauses ? "presentCauses" : "noCauses"}
        variants={{
          presentCauses: {
            bottom: 0,
            transition: {duration: 0.5, ease: "easeInOut"},
          },
          noCauses: {bottom: -48},
        }}
        className={cx(
          hstack({
            height: 36,
            width: 255,
            padding: 2,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "lightGrey",
            borderRadius: 10,
            position: "relative",
          }),
          className,
        )}
      >
        <SelectableButton
          className={css({flex: 1})}
          title={t("cases.case.chat")}
          selected={tab === "conversation"}
          onClick={(): void => {
            onChangeTab("conversation")
          }}
        />
        <SelectableButton
          className={css({flex: 1})}
          title={t("cases.case.causes")}
          badge={
            <div
              className={css({
                position: "relative",
              })}
            >
              <Badge count={causesCount} />
              <motion.div
                animate={{
                  scale: [1, 0.8, 1],
                }}
                transition={{
                  duration: 0.7,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className={css({
                  position: "absolute",
                  top: -6,
                  right: -6,
                  width: 12,
                  height: 12,
                  backgroundColor: "#FF4F69",
                  zIndex: 1,
                  borderRadius: "50%",
                })}
              />
            </div>
          }
          selected={tab === "causes"}
          onClick={(): void => {
            onChangeTab("causes")
          }}
        />
      </motion.div>
    </motion.div>
  )
}
