import type {Part as PartModel} from "@ri2/rc-db"
import {FC} from "react"
import {useSearchParams} from "react-router-dom"

import {Button} from "@frontend/components/ui"
import infoCircleIcon from "@frontend/design/icons/info-circle.svg"
import missingPartImage from "@frontend/design/icons/missing-part-image.svg"
import {useTranslation} from "@frontend/i18n"
import {addItemsToCart} from "@frontend/utils/appHostFunctions"
import {getImageUrl} from "@frontend/utils/getImageUrl"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

interface Props {
  part: PartModel
  mode: "single" | "list"
  className?: string
}

export const Part: FC<Props> = ({
  part: {id, image, price, manufacturerPartNumber, name, purchaseUrl},
  mode,
  className,
}) => {
  const t = useTranslation()

  const onAddToCart = (): void => {
    addItemsToCart([
      {
        id,
        oem_number: manufacturerPartNumber,
        name: name ?? undefined,
        model_number: manufacturerPartNumber,
        price: price != null ? price / 100 : undefined,
      },
    ])
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()

  const onLearnMore = (): void => {
    setSearchParams({
      id: id.toString(),
      oem_number: manufacturerPartNumber,
      name: name ?? "",
      model_number: manufacturerPartNumber,
      price: price != null ? (price / 100).toString() : "",
    })

    window.open(purchaseUrl, "_blank")
  }

  return (
    <div
      className={cx(
        mode === "single"
          ? vstack({
              gap: 8,
              alignItems: "stretch",
              paddingY: 16,
            })
          : hstack({
              borderBottomColor: "rgba(255, 255, 255, 0.1)",
              paddingY: 16,
              gap: 12,
              alignItems: "center",
            }),
        className,
      )}
    >
      <img
        src={image?.url ? getImageUrl(image.url) : missingPartImage}
        alt=""
        className={cx(
          css({
            objectFit: "cover",
            borderRadius: 16,
          }),
          mode === "single"
            ? css({width: "100%", aspectRatio: "274/184"})
            : css({height: 88, width: 88}),
        )}
      />
      <div
        className={vstack({
          flexGrow: 1,
          gap: 8,
          alignItems: "stretch",
        })}
      >
        <p
          className={cx(
            css({
              color: "white",
              lineClamp: 3,
            }),
            mode === "single"
              ? css({
                  paddingY: 4,
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "24px",
                })
              : css({
                  fontSize: 13,
                  fontWeight: 400,
                  lineHeight: "20px",
                }),
          )}
        >
          {name}
        </p>
        <div
          className={hstack({
            gap: 4,
          })}
        >
          <Button
            type="unstyled"
            onClick={onAddToCart}
            className={cx(
              hstack({
                flexGrow: 1,
                flexShrink: 1,
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "rgba(53, 94, 190, 1)",
                borderRadius: 8,
                boxShadow:
                  "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset",
                color: "white",
              }),
              mode === "single"
                ? css({
                    height: 36,
                    paddingX: 12,
                    fontSize: 16,
                  })
                : css({
                    height: 32,
                    paddingX: 8,
                    fontSize: 14,
                  }),
            )}
          >
            <span className={css({fontWeight: 400})}>
              {t("cause.diy.materials.addToCart")}
            </span>
            <span className={css({fontWeight: 700})}>
              {t("general.price", {price})}
            </span>
          </Button>
          <Button
            type="unstyled"
            onClick={onLearnMore}
            className={cx(
              hstack({
                gap: 6,
                flexGrow: 0,
                flexShrink: 0,
                alignItems: "center",
                justifyContent: "center",
                borderColor: "rgba(53, 94, 190, 1)",
                borderWidth: 1,
                borderRadius: 8,
                boxShadow:
                  "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset, 0px 0px 0px 1px rgba(16, 24, 40, 0.18) inset",
              }),
              mode === "single"
                ? css({
                    height: 36,
                    paddingX: 12,
                    color: "white",
                    fontSize: 14,
                    fontWeight: 400,
                    lineHeight: "20px",
                  })
                : css({
                    height: 32,
                    paddingX: 8,
                  }),
            )}
          >
            <img src={infoCircleIcon} alt="" />
            {mode === "single" && t("cause.diy.parts.learn")}
          </Button>
        </div>
      </div>
    </div>
  )
}
