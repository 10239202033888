import {FC} from "react"
import {ReactSVG} from "react-svg"

import missingPartImage from "@frontend/design/icons/missing-part.svg"
import {cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

interface Props {
  color: string
  className?: string
}

export const CauseIcon: FC<Props> = ({color, className}) => (
  <div
    className={cx(
      hstack({
        width: 56,
        height: 56,
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
      }),
      className,
    )}
    style={{
      backgroundColor: color,
    }}
  >
    <ReactSVG src={missingPartImage} />
  </div>
)
