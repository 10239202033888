import {FC} from "react"

import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Steps} from "../components/steps"
import {useDataFromParams} from "../params"

export const StepsPage: FC = () => {
  const {remediation, enrichedProductInfo} = useDataFromParams()

  return (
    <div
      className={hstack({
        alignItems: "stretch",
        gap: 35,
        minHeight: 0,
      })}
    >
      <Steps
        steps={remediation.diyRemediation.steps}
        cause={remediation.cause}
        enrichedProductInfo={enrichedProductInfo}
        className={css({
          paddingTop: 16,
          paddingX: 16,
        })}
      />
    </div>
  )
}
