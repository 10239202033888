import {FC, useState} from "react"
import {ReactSVG} from "react-svg"

import {Modal} from "@frontend/components"
import {EvaluationOverall} from "@frontend/components/feedback/evaluation-overall"
import {iconsColor} from "@frontend/design"
import likeIcon from "@frontend/design/icons/like.svg"
import {useTranslation} from "@frontend/i18n"
import {useSession} from "@frontend/session"
import {sleep} from "@frontend/utils/sleep"
import {useCreateFeedbackMutation} from "@frontend/utils/trpc"
import {FeedbackRating} from "@ri2/db/client"
import {cx} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

interface Props {
  onClose: () => void
}

export const FeedbackModal: FC<Props> = ({onClose}) => {
  const t = useTranslation()

  const {userRcId} = useSession()

  const [rating, setRating] = useState<FeedbackRating | undefined>()

  const createFeedbackMutation = useCreateFeedbackMutation(async () => {
    await sleep(2000)
    onClose()
  })

  const onRate = (newRating: FeedbackRating): void => {
    createFeedbackMutation.mutate({userRcId, rating: newRating})
    setRating(newRating)
  }

  return (
    <Modal
      title={t("feedback.general.feedbackModalTitle")}
      show
      message={
        rating ? t("feedback.thanks") : t("feedback.general.feedbackModal")
      }
      onDismiss={onClose}
      width={610}
      icon={
        <div
          className={vstack({
            borderRadius: 8,
            backgroundColor: "bgBlue",
            width: 36,
            height: 36,
            justifyContent: "center",
          })}
        >
          <ReactSVG
            src={likeIcon}
            className={cx(
              iconsColor({
                color: "lightBlue",
              }),
            )}
          />
        </div>
      }
    >
      <div className={vstack({alignItems: "stretch"})}>
        <EvaluationOverall rating={rating} onRate={onRate} />
      </div>
    </Modal>
  )
}
